import Card from "@mui/material/Card";
import {Box, IconButton, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RenameProgramDialog from "./RenameProgramDialog";
import DeleteProgramDialog from "./DeleteProgramDialog";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

const DashboardProgramCard = ({program, cloneProgram, deleteProgram, fetchData, successSnackbarRef}) => {

    const {t} = useTranslation()
    let navigate = useNavigate();


    return (
        <Card
            sx={{width: "12%", overflow: "hidden", marginRight: "1em"}} variant="outlined"
            key={program.id} programId={program.id} onClick={e => navigate(`/program/${program.id}/equipments`)}
        >
            <img src={program.program_image ? program.program_image : "/no_program_image.png"}
                 style={{objectFit: "cover", width: "100%", height: "10em"}} alt={"Programm Bild"}/>
            <Box sx={{padding: ".5em"}}>
                <Typography sx={{fontWeight: "bold"}}>
                    {program.title}
                </Typography>
                <Typography sx={{color: "darkgrey"}} gutterBottom={1}>
                    {new Date(program.created).toLocaleDateString('de-DE', {
                        year: 'numeric', month: '2-digit', day: '2-digit'
                    })}
                </Typography>

                <Tooltip title={t('dashboard.cloneProgram')}>
                    <IconButton aria-label="Clone Program" programId={program.id} size="small" onClick={e => cloneProgram(e)}>
                        <ContentCopyIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>

                <RenameProgramDialog origProgramTitle={program.title} programId={program.id}
                                     successSnackbarRef={successSnackbarRef} fetchData={fetchData}/>

                <DeleteProgramDialog mainAction={() => deleteProgram(program.id)} title={t('dashboard.deleteProgram')}
                                     description={t('dashboard.deleteProgramWarning')}/>
            </Box>
        </Card>
    )
}

export default DashboardProgramCard;