import {Avatar, Box, IconButton, TextField, Typography} from "@mui/material";
import _ from "./PerformerDetail.css"
import React, {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import DeletePerformerDialog from "./DeletePerformerDialog";

const PerformerRightContainer = ({performer, setSelectedPerformer, updatePerformer, deletePerformer, errors}) => {

    const {t} = useTranslation();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openAvaterEditMenu = (event) => {
        if (anchorEl === null)
            setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedPerformer({...performer, image: file});
            updatePerformer({...performer, image: file});
            handleClose();
        }
    };

    const deleteAvatarImage = () => {
        setSelectedPerformer({...performer, image: null});
        updatePerformer({...performer, image: null});
        handleClose();
    }

    if (performer) {
        return (
            <div className={"performerDetailContainer"}>
                <div className={"performerDetailItem"}>
                    <Box>
                        <div className="avatarContainer" onClick={e => openAvaterEditMenu(e)}>
                            <Avatar
                                className={"performerAvatar"}
                                sx={{width: "120px", height: "120px"}}
                                alt={performer.name} src={performer.image ? process.env.REACT_APP_BASE_URL + performer.image : ""}>
                            </Avatar>
                            <div className={"overlay"}></div>
                            <IconButton className={"editIcon"}>
                                <EditIcon sx={{color: "black"}}/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl} open={open}
                                onClose={() => handleClose()}>

                                <MenuItem onClick={() => null} sx={{padding: 0}}>
                                    <label htmlFor="fileInput" style={{cursor: "pointer", width: "100%", padding: "6px 16px"}}>
                                        {t('detail.performer.changeImage')}
                                        <input id="fileInput" type="file" hidden accept="image/*" onChange={handleFileChange}/>
                                    </label>
                                </MenuItem>

                                <MenuItem onClick={() => deleteAvatarImage()}>{t('detail.performer.removeImage')}</MenuItem>

                            </Menu>
                        </div>
                    </Box>
                </div>

                <div className={"performerDetailItem"}>
                    <TextField value={performer.name} size={"small"}
                               label={t('detail.performer.name')} variant="outlined" fullWidth
                               error={"name" in errors} onBlur={e => updatePerformer(performer)}
                               helperText={errors["name"]}
                               onChange={(e) => setSelectedPerformer({...performer, name: e.target.value})}
                    />
                </div>

                <div className={"performerDetailItem"}>
                    <TextField value={performer.email ? performer.email : ""} size={"small"}
                               label={t('detail.performer.email')} variant="outlined" fullWidth error={"email" in errors}
                               helperText={errors["email"]} onBlur={e => updatePerformer(performer)}
                               onChange={(e) => setSelectedPerformer({...performer, email: e.target.value})}
                    />
                </div>

                <div className={"performerDetailItem"}>
                    <DeletePerformerDialog
                        mainAction={(e) => deletePerformer(performer.id)}
                    />
                </div>

            </div>
        )
    }

}

export default PerformerRightContainer
