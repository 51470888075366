import React, {useRef} from "react";
import {Button, TextField, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ChoreographiesRightContainerDetails from "./ChoreographiesRightContainerDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import ChoreographiesRightContainerDrills from "./ChoreographiesRightContainerDrills";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";
import {isNumber, sleep} from "../../../utils/DefaultHelpers";
import ErrorSnackbar from "../../../components/ErrorSnackbar";
import {getNumberOfTactsInMusic} from "../../../utils/ChoreographyHelpers";

const ChoreographiesRightContainer = ({
                                          sendToServer, deleteChoreographyDetail, createChoreographyTactGroup, choreographyTactGroups,
                                          choreographyTactGroup, setSelectedChoreographyTactGroup, updateChoreographyTactGroup,
                                          deleteChoreographyTactGroup, sendDetailToServer, createChoreographyDetail,
                                          sendDrillToServer, performers, selectedChoreographyDrill, isRenderView, modeChange
                                      }) => {


    const {t} = useTranslation();
    const snackbarRef = useRef();

    const updateTact = (choreographyTactGroup) => {
        const tactsInMusic = getNumberOfTactsInMusic(choreographyTactGroup.choreography_group_object.music_object);
        if (choreographyTactGroup.tact_number <= tactsInMusic && choreographyTactGroup.tact_number > 0){
            updateChoreographyTactGroup(choreographyTactGroup);
        }
        else if (snackbarRef.current) sleep(150).then(e => { snackbarRef.current.openSnackbar(t('detail.choreography.tactNumberSaveError'))});
    }


    if (choreographyTactGroup && choreographyTactGroups) {

        const newChoreographyDetail = {
            beat: -1, choreography_tact_group: choreographyTactGroup.id,
            description: "", ordering: choreographyTactGroup.choreographyDetail.length + 1,
        };

        const newChoreographyTactGroup = {
            tact_number: choreographyTactGroup.tact_number + 1,
            choreography_group: choreographyTactGroup.choreography_group
        };

        let preTactGroups = choreographyTactGroups.filter(x => x.choreography_group === choreographyTactGroup.choreography_group && x.tact_number < choreographyTactGroup.tact_number);
        let postTactGroups = choreographyTactGroups.filter(x => x.choreography_group === choreographyTactGroup.choreography_group && x.tact_number > choreographyTactGroup.tact_number);

        let songTimestamp = choreographyTactGroup.choreography_group_object.music_object.start_time + choreographyTactGroup.choreography_group_object.music_object.tact_duration * choreographyTactGroup.choreography_group_object.music_object.tact * (choreographyTactGroup.tact_number - 2)
        if (songTimestamp < 0) songTimestamp = 0;

        return (<>
                <ErrorSnackbar ref={snackbarRef}/>

                <div className={"performerDetailItem"}>
                    <Typography variant={"h6"} sx={{marginBottom: "1.2em"}}>{t('detail.choreography.generalInformation')}</Typography>
                    <TextField fullWidth value={choreographyTactGroup.choreography_group_object.title} size={"small"}
                               label={t('detail.choreography.description')}
                               variant="outlined"
                               sx={{marginBottom: ".8em"}}
                               onBlur={(e) => sendToServer(choreographyTactGroup.choreography_group_object)}
                               onChange={(e) =>
                                   setSelectedChoreographyTactGroup(prevState => ({
                                       ...prevState,
                                       choreography_group_object: {
                                           ...prevState.choreography_group_object,
                                           title: e.target.value
                                       }
                                   }))
                               }/>
                    <TextField value={choreographyTactGroup.tact_number} size={"small"} label={t('detail.choreography.tact')} variant="outlined"
                               sx={{width: "50%", marginRight: "10%", marginBottom: ".8em"}}
                               onBlur={(e) => updateTact(choreographyTactGroup)}
                               onChange={(e) => isNumber(e.target.value) ? setSelectedChoreographyTactGroup({
                                   ...choreographyTactGroup, tact_number: e.target.value
                               }) : console.log("Only numbers allowed")}/>

                    {!isRenderView
                        ? <Button variant="contained" startIcon={<PlayArrowIcon/>} sx={{width: "100%"}}
                                  onClick={(e) => modeChange(songTimestamp)}>{t('detail.choreography.playProgram')}</Button>
                        : <Button variant="contained" startIcon={<ArrowBackIcon/>} sx={{width: "100%"}}
                                  onClick={(e) => modeChange(0)}>{t('detail.choreography.backToEditMode')}</Button>
                    }

                </div>

                <div className={"performerDetailItem"}>
                    <Typography variant={"h6"} sx={{marginBottom: ".6em"}}>{t('detail.choreography.details')}</Typography>

                    {preTactGroups.map((item, index) => (
                        <ChoreographiesRightContainerDetails
                            snackbarRef={snackbarRef}
                            key={`rightContainerDetailsPre-${index}`}
                            choreographyTactGroup={item}
                            sendDetailToServer={sendDetailToServer}
                            deleteChoreographyDetail={deleteChoreographyDetail}
                            setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                            readonly={true}
                        />
                    ))}

                    <ChoreographiesRightContainerDetails
                        snackbarRef={snackbarRef}
                        choreographyTactGroup={choreographyTactGroup}
                        sendDetailToServer={sendDetailToServer}
                        deleteChoreographyDetail={deleteChoreographyDetail}
                        setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                        readonly={false}
                    />

                    {postTactGroups.map((item, index) => (
                        <ChoreographiesRightContainerDetails
                            snackbarRef={snackbarRef}
                            key={`rightContainerDetailsPost-${index}`}
                            choreographyTactGroup={item}
                            sendDetailToServer={sendDetailToServer}
                            deleteChoreographyDetail={deleteChoreographyDetail}
                            setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                            readonly={true}
                        />
                    ))}

                    <Button variant="contained" color="primary" onClick={(e) => createChoreographyDetail(newChoreographyDetail)}
                            sx={{marginRight: "1%", minWidth: "19%"}}><AddIcon/>
                    </Button>
                    <Button variant="outlined" color="primary" onClick={(e) => createChoreographyTactGroup(newChoreographyTactGroup)}>
                        {t('detail.choreography.continueDrill')}
                    </Button>

                </div>

                <div className={"performerDetailItem"}>
                    <ChoreographiesRightContainerDrills
                        choreographyTactGroup={choreographyTactGroup}
                        selectedChoreographyDrill={selectedChoreographyDrill}
                        performers={performers}
                        sendDrillToServer={sendDrillToServer}
                        setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                    />
                </div>

                <div className={"performerDetailItem"} style={{marginBottom: "2em"}}>
                    <Button variant="outlined" color="error" sx={{"padding": "7px 0px", minWidth: "100%"}}
                            onClick={(e) => deleteChoreographyTactGroup()} startIcon={<DeleteIcon/>}>
                        {t('detail.choreography.deleteGroup')}
                    </Button>
                </div>
            </>
        )
    }

}


export default ChoreographiesRightContainer
