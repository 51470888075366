const DefaultContentContainer = ({children, onKeyDown, onKeyUp = e => null}) => {




    return (
        <div onKeyDown={e => onKeyDown(e)}
             onKeyUp={onKeyUp}
             tabIndex="0" style={{height: "100%"}}>
            {children}
        </div>
    )
}

export default DefaultContentContainer
