import {useParams} from "react-router-dom";
import ProgramHeader from "../../../components/ProgramHeader";
import React, {useEffect, useRef, useState} from "react";
import ResizeableComponent from "../../../components/ResizeableComponent";
import ProgramEditHeader from "../../../components/ProgramEditHeader";
import DefaultContentContainer from "../../../components/DefaultContentContainer";
import {musicService} from "../../../services/MusicService";
import DefaultListItem from "../../../components/DefaultListItem";
import MusicRightContainer from "./MusicRightContainer";
import MusicMediaPlayer from "./MusicMediaPlayer";
import {programService} from "../../../services/ProgramService";
import MusicActionBar from "./MusicActionBar";
import ErrorSnackbar from "../../../components/ErrorSnackbar";
import {useTranslation} from "react-i18next";

const MusicsMain = () => {

    let {programId} = useParams()
    const {t} = useTranslation()

    const [selectedMusic, setSelectedMusic] = React.useState(null);
    const [musics, setMusics] = React.useState([]);
    const [program, setProgram] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);

    const musicMediaPlayerRef = useRef(null);
    const snackbarRef = React.useRef();

    const fetchMusic = async () => {
        return await musicService.getAllMusics();
    }

    const createMusic = (music) => {
        try {
            musicService.createMusic(music).then(response => {
                if (response.status === 201) {
                    setMusics([...musics, response.data]);
                } else if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
            });
        } catch (error) {
            if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
        }
    }

    const updateMusic = (music) => {
        try {
            musicService.updateMusic(music).then(musicResponse => {
                if (musicResponse.status === 200)
                    setMusics((prevMusics) => prevMusics.map((m) => (m.id === music.id ? musicResponse.data : m)));
                else if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
            });
        } catch (error) {
            if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
        }
    };

    const deleteMusic = (music) => {
        try {
            musicService.deleteMusic(music.id).then(musicResponse => {
                if (musicResponse.status === 204) {
                    setMusics((prevMusics) => prevMusics.filter((x) => x.id !== music.id));
                    setSelectedMusic(null);
                } else {
                    if (snackbarRef.current) snackbarRef.current.openSnackbar(t('deleteError'));
                }
            });
        } catch (error) {
            if (snackbarRef.current) snackbarRef.current.openSnackbar(t('deleteError'));
        }
    }

    const splitMusic = (musicToUpdate, musicToCreate) => {
        createMusic(musicToCreate);
        updateMusic(musicToUpdate);
    }

    const playSongAtSeconds = (timeInSeconds) => {
        if (musicMediaPlayerRef.current) {
            musicMediaPlayerRef.current.playSongAtSeconds(timeInSeconds);
        }
    };

    const pauseSong = () => {
        if (musicMediaPlayerRef.current) {
            musicMediaPlayerRef.current.pauseSong();
        }
    };

    const fetchProgram = async () => {
        return await programService.getProgram(programId);
    }


    useEffect(() => {
        fetchMusic().then(musicRequest => setMusics(musicRequest["data"].filter(x => x.program.toString() === programId)));
        fetchProgram().then(program => setProgram(program["data"]));
    }, []);

    useEffect(() => {
        if (selectedMusic) updateMusic(selectedMusic);
    }, [selectedMusic]);


    return (
        <DefaultContentContainer onKeyDown={() => null}>
            <ProgramEditHeader>
                <ProgramHeader programId={programId} selectedMenu={"musics"}/>
                <MusicActionBar createMusic={createMusic} setMusics={setMusics}/>
            </ProgramEditHeader>

            <main>
                <ResizeableComponent>
                    <div style={{marginTop: "10px"}}>
                        {musics.map((music, i) => {
                            return (<DefaultListItem text={music.title} key={i}
                                                     selected={selectedMusic && music.id === selectedMusic.id}
                                                     onClick={e => setSelectedMusic(music)}/>)
                        })}
                    </div>

                    <MusicMediaPlayer
                        ref={musicMediaPlayerRef} musics={musics} setSelectedMusic={setSelectedMusic} setIsPlaying={setIsPlaying}
                        selectedMusic={selectedMusic} program={program}/>

                    <MusicRightContainer
                        music={selectedMusic} setSelectedMusic={setSelectedMusic}
                        playSongAtSeconds={playSongAtSeconds} pauseSong={pauseSong} isPlaying={isPlaying}
                        deleteMusic={deleteMusic} splitMusic={splitMusic}
                    />

                </ResizeableComponent>
            </main>
            <ErrorSnackbar ref={snackbarRef}/>
        </DefaultContentContainer>
    )
}

export default MusicsMain
