import axios from 'axios';
import {requestService} from "./RequestService";

export const programService = {
    getAllPrograms, getProgram, createProgram, cloneProgram, updateProgram, deleteProgram
}

async function getAllPrograms() {
    const url = process.env.REACT_APP_PROGRAM_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function getProgram(programId) {
    const url = process.env.REACT_APP_PROGRAM_URL + programId + "/"
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function createProgram(program) {
    const url = process.env.REACT_APP_PROGRAM_URL
    let config = requestService.authRequestConfig();
    config['headers']['Content-Type'] = 'multipart/form-data'
    return await axios.post(url, program, config);
}


async function updateProgram(program) {
    const url = process.env.REACT_APP_PROGRAM_URL + program.id + "/"
    let config = requestService.authRequestConfig();
    config['headers']['Content-Type'] = 'multipart/form-data'
    return await axios.put(url, program, config);
}

async function deleteProgram(programId) {
    const url = process.env.REACT_APP_PROGRAM_URL + programId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}

async function cloneProgram(programId) {
    const url = process.env.REACT_APP_PROGRAM_URL + programId + "/clone/"
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}