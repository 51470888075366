import React from "react"
import ProgramDetailStyles from './ProgramDetail.css'
import {useTranslation} from 'react-i18next'
import {useParams} from "react-router-dom";
import InstallationDrawingMiddleContainer from "./InstallationDrawingMiddleContainer";
import {installationDrawingService} from "../../../services/InstallationDrawingService";
import InstallationDrawingActionBar from "./InstallationDrawingActionBar";
import ResizeableComponent from "../../../components/ResizeableComponent";
import ProgramHeader from "../../../components/ProgramHeader";
import ProgramEditHeader from "../../../components/ProgramEditHeader";
import DefaultContentContainer from "../../../components/DefaultContentContainer";
import InstallationDrawingLeftContainer from "./InstallationDrawingLeftContainer";
import InstallationDrawingRightContainer from "./InstallationDrawingRightContainer";
import {programService} from "../../../services/ProgramService";
import ErrorSnackbar from "../../../components/ErrorSnackbar";


/**
 * This Component provides the Overview for all Children.
 *
 * @return {JSX.Element}
 */
const InstallationDrawingMain = () => {

    const {t} = useTranslation()

    let {programId} = useParams()
    const snackbarRef = React.useRef();

    const [program, setProgram] = React.useState([]);
    const [selectedInstallationDrawing, setSelectedInstallationDrawing] = React.useState(null);
    const [installationDrawingClipboard, setInstallationDrawingClipboard] = React.useState(null);
    const [installationDrawings, setInstallationDrawings] = React.useState([]);

    const updateProgram = async (program) => {
        programService.updateProgram(program).then(response => {
            if (response.status === 200) {
                setProgram(response.data);
            } else {
                if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
            }
        })
    }

    const addInstallationDrawing = (installationDrawing) => {
        installationDrawingService.createInstallationDrawing(installationDrawing).then(response => {
            if (response.status === 201) {
                setInstallationDrawings(current => [...current, response.data]);
                setSelectedInstallationDrawing(response.data);
            } else {
                if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
            }
        })
    }

    const updateInstallationDrawing = (installationDrawing) => {
        installationDrawingService.updateInstallationDrawing(installationDrawing).then(response => {
            if (response.status === 200) {
                stateUpdateInstallationDrawing(response.data);
            } else {
                if (snackbarRef.current) snackbarRef.current.openSnackbar(t('saveError'));
            }
        })
    };

    const deleteInstallationDrawing = (installationDrawing) => {
        installationDrawingService.deleteInstallationDrawing(installationDrawing.id).then(response => {
            if (response.status === 204) {
                setInstallationDrawings(current => current.filter(item => item.id !== installationDrawing.id));
                setSelectedInstallationDrawing(null);
            } else {
                if (snackbarRef.current) snackbarRef.current.openSnackbar(t('deleteError'));
            }
        })

    }

    const stateUpdateInstallationDrawing = (installationDrawing) => {
        setInstallationDrawings((installationDrawings) =>
            installationDrawings.map((drawing) =>
                drawing.id === installationDrawing.id ? installationDrawing : drawing
            ));
        setSelectedInstallationDrawing(installationDrawing);
    }

    const handleKeyUp = (e) => {
        switch (e.keyCode) {
            case 38: // Up arrow key
            case 40: // Down arrow key
            case 39: // Right arrow key
            case 37: // Left arrow key
                if (selectedInstallationDrawing) {
                    updateInstallationDrawing(selectedInstallationDrawing);
                }
                break;
            default:
                break
        }
    }

    const handleKeyDown = async (e) => {
        switch (e.keyCode) {
            case 46: // Delete key
                if (selectedInstallationDrawing)
                    await deleteInstallationDrawing(selectedInstallationDrawing);
                break;
            case 38: // Up arrow key
                if (selectedInstallationDrawing)
                    stateUpdateInstallationDrawing({...selectedInstallationDrawing, y: selectedInstallationDrawing.y - 1})
                break;
            case 40: // Down arrow key
                if (selectedInstallationDrawing)
                    stateUpdateInstallationDrawing({...selectedInstallationDrawing, y: selectedInstallationDrawing.y + 1})
                break;
            case 39: // Right arrow key
                if (selectedInstallationDrawing)
                    stateUpdateInstallationDrawing({...selectedInstallationDrawing, x: selectedInstallationDrawing.x + 1})
                break;
            case 37: // Left arrow key
                if (selectedInstallationDrawing)
                    stateUpdateInstallationDrawing({...selectedInstallationDrawing, x: selectedInstallationDrawing.x - 1})
                break;
            case 67: // "C" key (for copy)
                if (e.ctrlKey || e.metaKey) {
                    if (selectedInstallationDrawing)
                        setInstallationDrawingClipboard({...selectedInstallationDrawing})
                }
                break;
            case 86: // "V" key (for paste)
                if (e.ctrlKey || e.metaKey)
                    addInstallationDrawing({...installationDrawingClipboard, name: installationDrawingClipboard.name + " Copy"})
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        installationDrawingService.getAllInstallationDrawings().then(response => setInstallationDrawings(response["data"].filter(x => x.program.toString() === programId)));
        programService.getProgram(programId).then(programResponse => setProgram(programResponse["data"]));
    }, []);


    return (
        <DefaultContentContainer onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <ProgramEditHeader>
                <ProgramHeader programId={programId} selectedMenu="equipments"/>
                <InstallationDrawingActionBar addInstallationDrawing={addInstallationDrawing}/>
            </ProgramEditHeader>
            <main>
                <ResizeableComponent>
                    <InstallationDrawingLeftContainer
                        installationDrawings={installationDrawings}
                        selectedInstallationDrawing={selectedInstallationDrawing} setSelectedInstallationDrawing={setSelectedInstallationDrawing}/>
                    <InstallationDrawingMiddleContainer
                        program={program} updateProgram={updateProgram}
                        installationDrawings={installationDrawings}
                        updateInstallationDrawing={updateInstallationDrawing}
                        selectedInstallationDrawing={selectedInstallationDrawing} setSelectedInstallationDrawing={setSelectedInstallationDrawing}
                    />
                    <InstallationDrawingRightContainer
                        installationDrawing={selectedInstallationDrawing}
                        setSelectedInstallationDrawing={setSelectedInstallationDrawing} sendToServer={updateInstallationDrawing}
                    />
                </ResizeableComponent>
            </main>
            <ErrorSnackbar ref={snackbarRef}/>
        </DefaultContentContainer>
    )
}

export default InstallationDrawingMain
