import axios from 'axios';
import {requestService} from "./RequestService";

export const performerService = {
    getAllPerformers, updatePerformer, createPerformer, deletePerformer
}

async function getAllPerformers() {
    const url = process.env.REACT_APP_PERFORMER_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function updatePerformer(performer) {
    const url = process.env.REACT_APP_PERFORMER_URL + performer.id + "/"
    let config = requestService.authRequestConfig();
    if ("image" in performer && typeof performer["image"] !== 'string' && performer["image"] !== null) config['headers']['Content-Type'] = 'multipart/form-data'
    else if ("image" in performer && performer["image"] === null) performer["image"] = null
    else delete performer.image;
    return await axios.put(url, performer, config);
}

async function createPerformer(performer) {
    const url = process.env.REACT_APP_PERFORMER_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, performer, config);
}

async function deletePerformer(performerId) {
    const url = process.env.REACT_APP_PERFORMER_URL + performerId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}