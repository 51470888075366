import React from "react";
import {useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";


const ProgramHeader = ({programId, selectedMenu}) => {

    const {t} = useTranslation();
    let navigate = useNavigate();

    const MenuItemStyled = styled(MenuItem)(({ theme, isSelected }) => ({
        display: 'inline',
        paddingBottom: '.25rem !important',
        marginRight: '0rem',
        borderBottom: isSelected ? `2px solid ${theme.palette.primary.main} !important` : 'none',
        color: isSelected ? `${theme.palette.primary.main} !important` : 'inherit',
        cursor: 'pointer',
        fontSize: ".95rem"
    }));


    return (
        <div className="horiz-menu" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <MenuItemStyled isSelected={selectedMenu === "equipments"} onClick={x => navigate(`/program/${programId}/equipments`)}>
                {t('menu.installationDrawing')}
            </MenuItemStyled>
            <MenuItemStyled isSelected={selectedMenu === "musics"} onClick={x => navigate(`/program/${programId}/musics`)}>
                {t('menu.music')}
            </MenuItemStyled>
            <MenuItemStyled isSelected={selectedMenu === "performer"} onClick={x => navigate(`/program/${programId}/performer`)}>
                {t('menu.performer')}
            </MenuItemStyled>
            <MenuItemStyled isSelected={selectedMenu === "choreographies"} onClick={x => navigate(`/program/${programId}/choreographies`)}>
                {t('menu.choreography')}
            </MenuItemStyled>
        </div>
    )
}

export default ProgramHeader

