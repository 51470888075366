import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from "react-i18next";
import {StyledMenuItem} from "../../../components/StyledMenuItem";


const PerformerActionBar = ({addPerformer}) => {

    const addPerformer_ = () => {
        addPerformer();
    }

    const {t} = useTranslation();
    return (
        <div className="horiz-menu" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <StyledMenuItem
                onClick={(e) => addPerformer_()}
                isactive={false}
            >
                {t('detail.performer.addPerformer')}
            </StyledMenuItem>
        </div>
    );
    }

export default PerformerActionBar;
