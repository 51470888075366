import React from 'react';
import {Stage, Layer, Rect, Transformer} from 'react-konva';
import InstallationDrawingImage from "./InstallationDrawingImage";


const InstallationDrawingMiddleContainer = ({
                                                program, updateProgram,
                                                installationDrawings,
                                                updateInstallationDrawing,
                                                setSelectedInstallationDrawing,
                                                selectedInstallationDrawing
                                            }) => {

    const [stageSelected, setStageSelected] = React.useState(false)

    const shapeRef = React.useRef();
    const trRef = React.useRef();


    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedInstallationDrawing(null);
            setStageSelected(false);
        }
    };

    const updateProgram_ = (e) => {
        if (shapeRef.current) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);
            const width = Math.max(5, Math.round(node.width() * scaleX));
            const height = Math.max(Math.round(node.height() * scaleY));

            updateProgram({id: program.id, stage_x1: node.x(), stage_y1: node.y(), stage_x2: width + node.x(), stage_y2: height + node.y()})
        }
    }


    React.useEffect(() => {
        if (stageSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [stageSelected]);


    return (
        <Stage
            width={window.innerWidth}
            height={window.innerHeight * 0.908}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            style={{backgroundColor: "lightgray"}}>

            <Layer>

                <Rect
                    onDragEnd={e => updateProgram_(e)}
                    onTransformEnd={e => updateProgram_(e)}
                    ref={shapeRef}
                    draggable={true}
                    x={program.stage_x1}
                    y={program.stage_y1}
                    width={program.stage_x2 - program.stage_x1}
                    height={program.stage_y2 - program.stage_y1}
                    onClick={e => setStageSelected(true)}
                    fill={"#c5c5c5"}
                />

                {installationDrawings.map((installationDrawing, i) => {
                    return (
                        <InstallationDrawingImage
                            key={i}
                            shapeProps={installationDrawing}
                            isSelected={selectedInstallationDrawing && installationDrawing.id === selectedInstallationDrawing.id}
                            onSelect={() => setSelectedInstallationDrawing(installationDrawing)}
                            onChange={(newAttrs) => updateInstallationDrawing(newAttrs)}
                        />
                    );
                })}

                {stageSelected && (
                    <Transformer
                        ref={trRef} rotateEnabled={false}
                        boundBoxFunc={(oldBox, newBox) => newBox.width < 5 || newBox.height < 5 ? oldBox : newBox}
                    />
                )}
            </Layer>
        </Stage>
    );
};

export default InstallationDrawingMiddleContainer;


