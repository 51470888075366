import axios from 'axios';
import {requestService} from "./RequestService";

export const installationDrawingService = {
    getAllInstallationDrawings, updateInstallationDrawing, createInstallationDrawing, deleteInstallationDrawing
}

async function getAllInstallationDrawings() {
    const url = process.env.REACT_APP_INSTALLATION_DRAWING_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function updateInstallationDrawing(installationDrawing) {
    const url = process.env.REACT_APP_INSTALLATION_DRAWING_URL + installationDrawing.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, installationDrawing, config);
}

async function createInstallationDrawing(installationDrawing) {
    const url = process.env.REACT_APP_INSTALLATION_DRAWING_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, installationDrawing, config);
}

async function deleteInstallationDrawing(installationDrawingId) {
    const url = process.env.REACT_APP_INSTALLATION_DRAWING_URL + installationDrawingId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}