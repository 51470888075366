import {Layer, Stage} from "react-konva";
import InstallationDrawingImage from "../installationDrawings/InstallationDrawingImage";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import ReactAudioPlayer from "react-audio-player";
import ChoreographyAvatar from "./ChoreographyAvatar";
import {useTranslation} from "react-i18next";


const ChoreographiesMiddleRenderContainer = forwardRef(({modeChange, installationDrawings, src, musics, choreographyTactGroups}, ref) => {

        const [volume, setVolume] = useState(0.35);
        const audioPlayerRef = useRef(null);
        const [displayElements, setDisplayElements] = useState([]);
        const {t} = useTranslation();


        const renderAtTime = (seconds) => {
            const timeStamp = (seconds * 1000) - 10
            let currentMusics = musics.filter(music => music.start_time < timeStamp && music.end_time > timeStamp);

            let displayElements_ = [];

            if (currentMusics.length === 0) {
                setDisplayElements([]);
            } else {
                const tactGroupsOfCurrentSong = choreographyTactGroups.filter(x => x.choreography_group_object.music === currentMusics[0]["id"]);

                let results = {};
                for (let tactGroupOfCurrentSong of tactGroupsOfCurrentSong) {
                    if (!(tactGroupOfCurrentSong.choreography_group in results)) {
                        results[tactGroupOfCurrentSong.choreography_group] = {
                            choreographyGroup: tactGroupOfCurrentSong.choreography_group,
                            drills: tactGroupOfCurrentSong.choreography_group_object.choreographyDrills,
                            details: []
                        }
                    }

                    for (let choreographyDetail of tactGroupOfCurrentSong.choreographyDetail) {
                        let beats_since_begging = currentMusics[0].tact * (tactGroupOfCurrentSong.tact_number - 1) + (choreographyDetail.beat - 1);
                        let timeStamp = currentMusics[0].start_time + beats_since_begging * currentMusics[0].tact_duration;
                        let detailItem = {runFlag: choreographyDetail.run_flag, timeStamp: timeStamp}
                        results[tactGroupOfCurrentSong.choreography_group]["details"].push(detailItem)
                    }
                }

                for (let result of Object.values(results)) {
                    if (result.details.length <= 1) continue;
                    result.details.sort((a, b) => a.timeStamp - b.timeStamp);
                    let appear = result.details[0].timeStamp;
                    let disappear = result.details[result.details.length - 1].timeStamp;
                    let run = 0;
                    let stop = 0;

                    let runFlagDetails = result.details.filter(x => x.runFlag);

                    if (runFlagDetails.length === 0) {
                        run = appear;
                        stop = disappear;
                    } else if (runFlagDetails.length === 1) {
                        run = runFlagDetails[0].timeStamp;
                        stop = disappear;
                    } else {
                        run = runFlagDetails[0].timeStamp;
                        stop = runFlagDetails[1].timeStamp;
                    }

                    for (let drill of result.drills) {
                        let delay = currentMusics[0].tact_duration * drill.delay;
                        if (appear + delay < timeStamp && disappear + delay > timeStamp) {
                            if (run + delay > timeStamp) {
                                let renderElement = {x: drill.x1, y: drill.y1, img: drill.performer_object ? drill.performer_object.image : null}
                                displayElements_.push(renderElement);
                            } else if (stop + delay < timeStamp) {
                                let renderElement = {x: drill.x2, y: drill.y2, img: drill.performer_object ? drill.performer_object.image : null}
                                displayElements_.push(renderElement);
                            } else {
                                let moveDuration = stop - run;
                                let passedMoveTime = timeStamp - (run + delay);
                                let moveDelta = passedMoveTime / moveDuration;
                                let x = drill.x1 + ((drill.x2 - drill.x1) * moveDelta);
                                let y = drill.y1 + ((drill.y2 - drill.y1) * moveDelta);
                                let renderElement = {x: x, y: y, img: drill.performer_object ? drill.performer_object.image : null}
                                displayElements_.push(renderElement);
                            }
                        }
                    }
                }
                setDisplayElements(displayElements_);
            }
        }

        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        useImperativeHandle(ref, () => ({
            playSongAtSeconds: async (timeInSeconds) => {
                if (audioPlayerRef.current) {
                    const audioElement = audioPlayerRef.current.audioEl;
                    if (audioElement) {
                        while (audioElement.current.readyState < 2) {
                            await sleep(20);
                        }
                        if (audioElement.current.readyState >= 2) {
                            audioElement.current.currentTime = timeInSeconds;
                            //  audioElement.current.fastSeek(timeInSeconds);
                            audioElement.current.play();
                        }
                    }
                }
            }
        }));


        return (
            <>
                <div style={{height: "6%", background: "#424A4C", color: "#E1E1E1", position: "relative", boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                        <h2 style={{margin: "auto"}}>{t('detail.choreography.playProgram')}</h2>
                    </div>
                </div>

                <Stage
                    width={window.innerWidth}
                    height={window.innerHeight * 0.908 * 0.86}
                    style={{backgroundColor: "#E1E1E1"}}>
                    <Layer>
                        {installationDrawings.map((installationDrawing, i) => {
                            return (
                                <InstallationDrawingImage listening={false} key={"installation" + i} shapeProps={installationDrawing}/>
                            );
                        })}

                        {
                            displayElements.map((ele, i) => {
                                    return (
                                        <ChoreographyAvatar x={ele.x} y={ele.y} img={ele.img}/>
                                    )
                                }
                            )
                        }
                    </Layer>

                </Stage>

                <div className={"centerFlexBox"} style={{height: "8%", background: "#424A4C", boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.1)"}}>
                    <ReactAudioPlayer
                        ref={audioPlayerRef}
                        style={{width: "90%", marginLeft: "5%", marginRight: "5%"}}
                        src={src}
                        volume={volume}
                        onVolumeChanged={(e) => setVolume(e.target.volume)}
                        controls
                        listenInterval={20}
                        onListen={(e) => renderAtTime(e)}
                    />
                </div>
            </>

        )

    })
;

export default ChoreographiesMiddleRenderContainer