export const localStorageService = {
    clear,
    setAccessToken, getAccessToken,
    setRefreshToken, getRefreshToken,
    setUserId, getUserId,
    setCurrentLanguage, getCurrentLanguage,
};

function clear() {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("userId");
}

function getAccessToken() {
    return localStorage.getItem("access");
}

function setAccessToken(accessToken) {
    localStorage.setItem("access", accessToken);
}

function getRefreshToken() {
    return localStorage.getItem("refresh");
}

function setRefreshToken(refreshToken) {
    localStorage.setItem("refresh", refreshToken);
}

function getUserId() {
    return localStorage.getItem("userId");
}

function setUserId(userId) {
    localStorage.setItem("userId", userId);
}

function getCurrentLanguage() {
    return localStorage.getItem("language");
}

function setCurrentLanguage(language) {
    localStorage.setItem("language", language);
}