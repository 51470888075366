import React, {useEffect, useState} from "react";
import {installationDrawingService} from "../../../services/InstallationDrawingService";
import {musicService} from "../../../services/MusicService";
import {choreographyService} from "../../../services/ChoreographyService";
import {programService} from "../../../services/ProgramService";
import {useParams} from "react-router-dom";
import {Layer, Stage, Text, Rect, Group, Arrow} from "react-konva";
import InstallationDrawingImage from "../installationDrawings/InstallationDrawingImage";
import {generateNumberList} from "../../../utils/DefaultHelpers";
import {getNumberOfTactsInMusic} from "../../../utils/ChoreographyHelpers";


const PerformerMiddleContainer = ({width, selectedPerformer}) => {

    let {programId} = useParams()

    const [installationDrawings, setInstallationDrawings] = React.useState([]);
    const [choreographyTactGroups, setChoreographyTactGroups] = React.useState([]);
    const [musics, setMusics] = React.useState([]);
    const [program, setProgram] = useState([]);


    useEffect(() => {
        installationDrawingService.getAllInstallationDrawings().then(installationDrawingsResponse => setInstallationDrawings(installationDrawingsResponse["data"].filter(x => x.program.toString() === programId)));
        musicService.getAllMusics().then(musicResponse => setMusics(musicResponse["data"].filter(x => x.program.toString() === programId)));
        choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId)));
        programService.getProgram(programId).then(program => setProgram(program["data"]));
    }, []);



    const getDrillsOfSelectedPerformer = (performerId) => {
        const tactGroupsWithSelectedPerformerDict = {};
        for (let i = 0; i < choreographyTactGroups.length; i++) {
            const group = choreographyTactGroups[i];
            const drills = group.choreography_group_object.choreographyDrills;

            for (let j = 0; j < drills.length; j++) {
                const drill = drills[j];
                if (drill.performer === selectedPerformer.id) {
                    const key = `${group.choreography_group_object.music}-${group.tact_number}`;
                    if (key in tactGroupsWithSelectedPerformerDict)
                        tactGroupsWithSelectedPerformerDict[key].push(group)
                    else
                        tactGroupsWithSelectedPerformerDict[key] = [group];
                    break;
                }
            }
        }
        return tactGroupsWithSelectedPerformerDict;
    }

    function generateMapping(num) {
        if (num > 0) {
            num -= 1
            const tact = Math.floor(num / 8);
            const beat = (num % 8) + 1;

            if (tact === 0) {
                return `  ${beat}`;
            } else {
                return `+${beat}`;
            }
        } else if (num < 0 && num >= -7) {
            const beat = (num % 8) + 1 + 8;
            return ` -${beat}`;
        } else {
            return "  ?"
        }
    }

    let stageWidth = program.stage_x2 - program.stage_x1;
    let stageHeight = program.stage_y2 - program.stage_y1;
    const drawingContainerScale = 0.5;
    const scale = drawingContainerScale * (((width - 120) / (stageWidth)) / 2);
    stageWidth /= drawingContainerScale;
    let y = 30;

    let drillsOfSelectedPerformer = selectedPerformer ? getDrillsOfSelectedPerformer() : {}


    let renderHeight_ = 30;
    for (let music of musics) {
        if((music.end_time - music.start_time) > 1000) {
            const numberOfIterations = getNumberOfTactsInMusic(music);
            renderHeight_ += (60 + Math.round(numberOfIterations / 2) * (20 + stageHeight * scale));
        }
    }
    renderHeight_ = Math.min(Math.floor(renderHeight_), 18000);

    return (
        <Stage
            width={width}
            height={renderHeight_}
            style={{backgroundColor: "lightgray"}}>

            <Layer>

                {
                    musics.map((music, i) => {
                        y += 60;
                        const numberOfIterations = getNumberOfTactsInMusic(music);
                        const iterationList = generateNumberList(numberOfIterations);

                        return (
                            <Group key={`music-${music.id}`}>
                                <Text fontSize={36} fill={"black"} text={music.title} x={40} y={y - 60}/>
                                {
                                    iterationList.map((i, _) => {
                                        let y_ = y;
                                        let x_ = i % 2 === 1 ? 40 : 0.5 * width;
                                        y += (i % 2 === 0 || i === iterationList.length) ? (stageHeight * scale) + 20 : 0;

                                        let key = `${music.id}-${i}`
                                        let iterationOfPerformerAtTact = key in drillsOfSelectedPerformer ? drillsOfSelectedPerformer[key] : [];
                                        let descriptionIndex = 0;

                                        return (
                                            <Group key={`iteration-${i}`}>
                                                <Rect x={x_} y={y_} width={stageWidth * scale} height={stageHeight * scale} fill={"#c5c5c5"}/>

                                                {installationDrawings.map((installationDrawing, i3) => {
                                                    let shapeProps = {
                                                        x: x_ + scale * (installationDrawing.x - program.stage_x1),
                                                        y: y_ + scale * (installationDrawing.y - program.stage_y1),
                                                        width: installationDrawing.width * scale,
                                                        height: installationDrawing.height * scale,
                                                        rotation: installationDrawing.rotation,
                                                        image: installationDrawing.image
                                                    }
                                                    return (
                                                        <InstallationDrawingImage listening={false} key={`$insdra-${installationDrawing.id}-${i}`}
                                                                                  shapeProps={shapeProps}/>
                                                    );
                                                })}

                                                <Text
                                                    fontSize={18} fill={"black"}
                                                    text={`#${i}`} fontStyle={"bold"}
                                                    x={(drawingContainerScale * stageWidth * scale) + x_ + 5}
                                                    y={(y_ + 20) + (descriptionIndex++) * 25}
                                                ></Text>

                                                {iterationOfPerformerAtTact.map((choreographyTactGroup, i4) => {

                                                    let delay = choreographyTactGroup.choreography_group_object.choreographyDrills.filter(x => x.performer === selectedPerformer.id)[0].delay;
                                                    choreographyTactGroup.choreographyDetail.sort((a, b) => a.tact - b.tact);

                                                    return (
                                                        <Group>
                                                            {
                                                                choreographyTactGroup.choreographyDetail.map((detail, i6) => {
                                                                    let displayedBeat = generateMapping(detail.beat + delay);
                                                                    return (
                                                                        <Text
                                                                            fontSize={18} fill={"black"}
                                                                            text={`${displayedBeat} ${detail.description}`}
                                                                            x={(drawingContainerScale * stageWidth * scale) + x_ + 5}
                                                                            y={(y_ + 20) + (descriptionIndex++) * 25}
                                                                        ></Text>

                                                                    )
                                                                })
                                                            }

                                                            {choreographyTactGroup.choreography_group_object.choreographyDrills.map((drillItem, i5) => {
                                                                let x1 = x_ + scale * (drillItem.x1 - program.stage_x1);
                                                                let x2 = x_ + scale * (drillItem.x2 - program.stage_x1);
                                                                let y1 = y_ + scale * (drillItem.y1 - program.stage_y1);
                                                                let y2 = y_ + scale * (drillItem.y2 - program.stage_y1);
                                                                return (
                                                                    <Arrow
                                                                        stroke={drillItem.performer === selectedPerformer.id ? "black" : "#555"}
                                                                        strokeWidth={6} points={[x1, y1, x2, y2]}
                                                                    />
                                                                )
                                                            })}
                                                        </Group>
                                                    )

                                                })}
                                            </Group>
                                        )
                                    })
                                }

                            </Group>
                        )
                    })
                }


            </Layer>
        </Stage>
    )
}


export default PerformerMiddleContainer