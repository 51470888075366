import * as React from 'react';
import {useTranslation} from "react-i18next";
import {StyledMenuItem} from "../../../components/StyledMenuItem";
import {useParams} from "react-router-dom";
import MusicCountDialog from "./MusicCountDialog";

const MusicActionBar = ({createMusic, setMusics}) => {

    const {t} = useTranslation();

    let {programId} = useParams()

    const defaultMusic = {title: "No Title", tact: 8, tact_duration: 0, start_time: 0, end_time: 0, program: programId}

    return (
        <div className="horiz-menu" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <StyledMenuItem
                onClick={(e) => createMusic(defaultMusic)}
                isactive={false}
            >
                {t('detail.music.addSong')}
            </StyledMenuItem>

            <MusicCountDialog setMusics={setMusics}/>
        </div>
    )
}

export default MusicActionBar;
