import {styled} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

export const StyledMenuItem = styled(MenuItem)(({theme, isactive}) => ({
    color: isactive ? theme.palette.primary.light : '',
    borderRadius: '3px',
    background: isactive ? theme.palette.secondary.light : '',
    marginRight: '2px',
    marginLeft: '2px',
    fontSize: '.95rem',
    paddingLeft: "8px",
    paddingRight: "8px",
}));