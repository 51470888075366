import {Button, ButtonGroup, TextField, Typography} from "@mui/material";
import React from "react";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {useTranslation} from "react-i18next";
import MusicSplitMusicDialog from "./MusicSplitMusicDialog";
import DeleteMusicDialog from "./DeleteMusicDialog";
import {isNumber} from "../../../utils/DefaultHelpers";

const MusicRightContainer = ({music, setSelectedMusic, playSongAtSeconds, pauseSong, deleteMusic, splitMusic, isPlaying}) => {


    const {t} = useTranslation();

    const updateNumberField = (e, fieldName) => {
        if (isNumber(e.target.value)) {
            setSelectedMusic({...music, [fieldName]: e.target.value})
        }
    }


    if (music) {
        return (<span>
                <div className={"performerDetailItem"}>
                    <Typography variant={"h6"} sx={{marginBottom: "1em"}}>{t('detail.music.generalInformation')}</Typography>
                    <TextField label={t('detail.music.title')} value={music.title}
                               variant="outlined" fullWidth size={"small"}
                               onChange={(e) => setSelectedMusic({...music, title: e.target.value})}/>
                </div>

                <div className={"performerDetailItem"}>
                    <TextField label={t('detail.music.startTime')} value={music.start_time}
                               sx={{width: "48%", marginRight: "4%"}}
                               variant="outlined" size={"small"}
                               onChange={(e) => updateNumberField(e, "start_time")}/>
                    <TextField label={t('detail.music.endTime')} value={music.end_time}
                               sx={{width: "48%"}}
                               variant="outlined" size={"small"}
                               onChange={(e) => updateNumberField(e, "end_time")}/>
                </div>

                <div className={"performerDetailItem"}>
                    <TextField label={t('detail.music.tact')} value={music.tact}
                               sx={{width: "48%", marginRight: "4%"}} variant="outlined" size={"small"}
                               onChange={(e) => updateNumberField(e, "tact")}/>
                    <TextField label={t('detail.music.cycleTime')} value={music.tact_duration}
                               sx={{width: "48%"}} variant="outlined" size={"small"}
                               onChange={(e) => updateNumberField(e, "tact_duration")}/>
                </div>

                <div className={"performerDetailItem"}>
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{width: "100%"}}>
                        <MusicSplitMusicDialog music={music} splitMusic={splitMusic}/>
                        <DeleteMusicDialog mainAction={(e) => deleteMusic(music)}/>
                    </ButtonGroup>
                </div>

                <div className={"performerDetailItem"}>
                    <Typography variant={"h6"} sx={{marginBottom: "1em"}}>{t('detail.music.quickActions')}</Typography>

                    <Typography>{t('detail.music.adjustCountingMethod')}</Typography>
                    <Button variant="outlined" sx={{marginBottom: "1em", width: "48%", marginRight: "4%", textTransform: "none"}}
                            onClick={(e) => setSelectedMusic({...music, tact_duration: Math.floor(music.tact_duration / 2)})}>
                        x2
                    </Button>
                    <Button variant="outlined" sx={{marginBottom: "1em", width: "48%", textTransform: "none"}}
                            onClick={(e) => setSelectedMusic({...music, tact_duration: music.tact_duration * 2})}>
                        /2
                    </Button>

                    <Typography>{t('detail.music.shiftTheBeat')}</Typography>
                    <Button variant="outlined" sx={{marginBottom: ".4em", width: "48%", marginRight: "4%", textTransform: "none"}}
                            onClick={(e) => setSelectedMusic({...music, start_time: music.start_time + music.tact_duration})}>
                        &#60;&#60;
                    </Button>
                    <Button variant="outlined" sx={{marginBottom: ".4em", width: "48%", textTransform: "none"}}
                            onClick={(e) => setSelectedMusic({...music, start_time: music.start_time - music.tact_duration})}>
                        &#62;&#62;
                    </Button>
                </div>

                <div className={"performerDetailItem"}>
                    <Typography variant={"h6"} sx={{marginBottom: "1em"}}>{t('detail.music.playThisSong')}</Typography>

                    {isPlaying
                        ? <Button variant="contained" startIcon={<PauseIcon/>} sx={{width: "50%"}} onClick={(e) => pauseSong()}>
                            {t('detail.music.pause')}
                        </Button>

                        : <Button variant="contained" startIcon={<PlayArrowIcon/>} sx={{width: "50%"}}
                                  onClick={(e) => playSongAtSeconds((music.start_time / 1000) - 4)}>
                            {t('detail.music.play')}
                        </Button>}

                </div>
            </span>)
    }

}

export default MusicRightContainer
