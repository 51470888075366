import React, {useState} from "react"
import MenuItem from '@mui/material/MenuItem';
import {StyledMenuItem} from "../../../components/StyledMenuItem";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ClickAwayListener, MenuList, Paper, Popper} from "@mui/material";


const InstallationDrawingActionBar = ({addInstallationDrawing}) => {

    const {t} = useTranslation();
    let {programId} = useParams()


    const installationDrawingDefaults = [
        {
            title: t("detail.installationDrawing.floor"),
            images: [
                {
                    height: 660,
                    width: 660,
                    rotation: 0,
                    x: 20,
                    y: 20,
                    image: "boden_sektor.png",
                    icon: "",
                    type: t("detail.installationDrawing.florBlue")
                },
                {height: 660, width: 660, rotation: 0, x: 20, y: 20, image: "boden.png", icon: "", type: t("detail.installationDrawing.florRed")},
                {
                    height: 100,
                    width: 600,
                    rotation: 0,
                    x: 20,
                    y: 20,
                    image: "bodenbahn.png",
                    icon: "",
                    type: t("detail.installationDrawing.floor12meters")
                }
            ]
        }, {
            title: t("detail.installationDrawing.mat"),
            images: [
                {height: 55, width: 80, rotation: 0, x: 20, y: 20, image: "6er.png", icon: "", type: t("detail.installationDrawing.smallMat")},
                {
                    height: 50,
                    width: 100,
                    rotation: 0,
                    x: 20,
                    y: 20,
                    image: "6er_100x50.png",
                    icon: "",
                    type: t("detail.installationDrawing.smallMat100x50")
                },
                {height: 80, width: 100, rotation: 0, x: 20, y: 20, image: "16er.png", icon: "", type: t("detail.installationDrawing.middleMat")},
                {height: 90, width: 150, rotation: 0, x: 20, y: 20, image: "40er.png", icon: "", type: t("detail.installationDrawing.bigMat")},
            ]
        },
        {
            title: t("detail.installationDrawing.parallelBars"),
            images: [
                {
                    height: 90,
                    width: 175,
                    rotation: 0,
                    x: 20,
                    y: 20,
                    image: "barren.png",
                    icon: "",
                    type: t("detail.installationDrawing.parallelBars")
                },
            ]
        },
        {
            title: t("detail.installationDrawing.highBar"),
            images: [
                {height: 6, width: 120, rotation: 0, x: 20, y: 20, image: "reck.png", icon: "", type: t("detail.installationDrawing.highBar")},
            ]
        },
        {
            title: t("detail.installationDrawing.trampoline"),
            images: [
                {
                    height: 60,
                    width: 60,
                    rotation: 0,
                    x: 20,
                    y: 20,
                    image: "minitramp.png",
                    icon: "",
                    type: t("detail.installationDrawing.miniTrampoline")
                },
            ]
        },
        {
            title: t("detail.installationDrawing.helpApparatus"),
            images: [
                {height: 25, width: 75, rotation: 0, x: 20, y: 20, image: "kasten.png", icon: "", type: t("detail.installationDrawing.gymBox")},
            ]
        }
    ]

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = React.useState(null);


    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);

        if (openMenuIndex === index) {
            setOpenMenuIndex(null);
        } else {
            setOpenMenuIndex(index);
        }
    };

    const onClose = () => {
        setOpenMenuIndex(null);
        setAnchorEl(null);
    }

    const addInstallationDrawing_ = (item) => {
        item.program = programId;
        addInstallationDrawing(item);
        setOpenMenuIndex(null);
    };

    return (
        <div className="horiz-menu" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>

            <div style={{fontSize: ".95rem" ,paddingTop: "6px", paddingRight: "0.25rem", color: ""}}>{t('detail.installationDrawing.addApparatus')}:</div>

            {installationDrawingDefaults.map((installationDrawingGroup, i) => {
                return (
                    <>
                        <StyledMenuItem onMouseUp={(e) => handleClick(e, i)} isActive={false}>
                            {installationDrawingGroup.title}
                        </StyledMenuItem>
                        <Popper
                            open={openMenuIndex === i}
                            anchorEl={anchorEl}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={onClose}>
                                    <MenuList
                                        autoFocusItem={openMenuIndex === i}
                                        key={`add-install-${installationDrawingGroup.title}`}
                                        open={true}
                                    >
                                        {
                                            installationDrawingGroup.images.map((item, i2) => {
                                                return (
                                                    <MenuItem onClick={e => addInstallationDrawing_(item)} key={`add-install-item-${item.type}`}>
                                                        {item.type}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popper>
                    </>
                )
            })}
        </div>
    )
}

export default InstallationDrawingActionBar;
