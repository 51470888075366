import React, {useState} from "react";
import {authenticationService} from '../../services/AuthenticationService';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, Collapse, TextField} from "@mui/material";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {useTheme} from '@mui/material/styles';


/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const Login = () => {

    const {t} = useTranslation();

    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });

    let navigate = useNavigate();

    const [showFailAlert, setShowFailAlert] = useState(false);

    const theme = useTheme();

    const handleSubmit = e => {
        e.preventDefault()
        authenticationService.login(credentials.email, credentials.password)
            .then(response => {
                if (response.status === 200) {
                    setShowFailAlert(false);
                    navigate("/dashboard");
                } else {
                    setShowFailAlert(true);
                }
            })

    }

    return (
        <div style={{height: "100%", display: "flex", alignItems: "center", overflow: "hidden", backgroundColor: theme.palette.background.default}}>

            <div style={{width: "37%", height: "100%"}}>
                <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderTopRightRadius: "0.5em",
                        borderBottomRightRadius: "0.5em"
                    }}
                    alt="Login Image"
                    src="/mainHeader.jpg"
                />

            </div>

            <div style={{width: "63%"}}>
                <div style={{paddingLeft: "15em", paddingRight: "15em"}}>
                    <div style={{paddingBottom: "3em"}}>
                        <LanguageSwitcher color={theme.palette.secondary.main}/>

                        <span>
                            <img src={"/choreo_creator_logo_2.svg"} alt={"Logo Choreo Creator"} style={{float: "right", width: "18rem"}}/>
                        </span>
                    </div>

                    <h1>{t('login.title')}</h1>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            style={{marginBottom: "1em"}}
                            fullWidth={true} variant="standard" label={t('login.email')}
                            onChange={(event) => setCredentials({
                                email: event.target.value, password: credentials.password
                            })}
                        />
                        <TextField
                            style={{marginBottom: "1em"}}
                            fullWidth={true} type="password" variant="standard" label={t('login.password')}
                            onChange={(event) => setCredentials({
                                email: credentials.email, password: event.target.value
                            })}
                        />
                        <div style={{textAlign: "right", marginBottom: "1em"}}>
                            <a style={{color: "gray", textDecoration: "none"}}
                               href="#"
                               onClick={e => navigate("/requestPasswordReset")}>
                                {t('login.forgotPassword')}
                            </a>
                        </div>
                        <Button
                            style={{marginBottom: "1em"}}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            type="submit">{t('login.login')}
                        </Button>

                        <div style={{color: "gray", textAlign: "center", marginBottom: "1em"}}>
                            {t('login.noAccount')}<a href="#" style={{color: theme.palette.secondary.main}}
                                                     onClick={() => navigate("/register")}>{t('login.registerHere')}</a>
                        </div>

                        <Collapse in={showFailAlert} sx={{width: "100%", marginTop: "2em"}}>
                            <Alert severity="error">{t('login.loginFail')}</Alert>
                        </Collapse>

                    </form>

                </div>
            </div>
        </div>
    );
}

export default Login;
