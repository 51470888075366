import React from 'react';
import {Image, Circle, Text} from 'react-konva';
import useImage from 'use-image';

const ChoreographyAvatar = ({img, x, y, onClick, opacity = 1, text = ""}) => {
    const [image] = useImage(
        img ? process.env.REACT_APP_BASE_URL + img : null
    );

    const squareSize = 50;

    return (
        <React.Fragment>
            {image ? (
                <Image
                    onMouseDown={onClick}
                    listening={true}
                    image={image}
                    x={x - (0.5 * squareSize)}
                    y={y - (0.5 * squareSize)}
                    width={squareSize}
                    zIndex={9999}
                    height={squareSize}
                    opacity={opacity}
                    cornerRadius={squareSize / 2}
                    crop={{
                        x: image.width > image.height ? (image.width - image.height) / 2 : 0,
                        y: image.width > image.height ? 0 : (image.height - image.width) / 2,
                        width: Math.min(image.width, image.height),
                        height: Math.min(image.width, image.height),
                    }}
                />
            ) : (
                <React.Fragment>
                    <Circle
                        x={x} y={y}
                        radius={squareSize / 2}
                        fill="#bbb"
                        onMouseDown={onClick}
                        listening={true}
                        opacity={opacity}
                    />
                    <Text
                        x={x - (0.5 * squareSize)} y={y - 8}
                        onMouseDown={onClick}
                        width={squareSize}
                        text={text}
                        fontSize={10}
                        align="center"
                        color="black"
                        verticalAlign="middle"
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ChoreographyAvatar;
