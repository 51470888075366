import * as React from 'react';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultConfirmDialog from "../../../components/DefaultConfirmDialog";


const DeletePerformerDialog = ({mainAction}) => {

    const {t} = useTranslation();
    const dialogRef = React.useRef();

    const handleClickOpen = (e) => {
        if (dialogRef.current) dialogRef.current.openDialog(e);

    };

    return (
        <>
            <Button startIcon={<DeleteIcon/>} sx={{width: "50%"}} color="error"
                    onClick={e => handleClickOpen(e)}>
                {t('detail.music.deleteSong')}
            </Button>

            <DefaultConfirmDialog
                ref={dialogRef}
                title={t('detail.music.deleteSong')}
                description={t('detail.music.deleteSongDescription')}
                mainActionText={t('delete')}
                mainAction={mainAction}
            />
        </>
    );
}

export default DeletePerformerDialog