import {StyledMenuItem} from "../../../components/StyledMenuItem";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";
import {programService} from "../../../services/ProgramService";
import {useParams} from "react-router-dom";
import {musicService} from "../../../services/MusicService";
import {Layer, Line, Stage} from "react-konva";
import ReactAudioPlayer from "react-audio-player";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {IconButton, Slider, Stack, Typography} from "@mui/material";
import {formatTime} from "../../../utils/DefaultHelpers";
import CustomScrollbar from "./StyledHorizontalScrollbarContainer";
import ErrorSnackbar from "../../../components/ErrorSnackbar";
import SuccessSnackbar from "../../../components/SuccessSnackbar";
import {useTheme} from '@mui/material/styles';

const MusicCountDialog = ({setMusics}) => {

    const {t} = useTranslation();

    let {programId} = useParams()

    const audioPlayerRef = useRef(null);


    const [timestamp, setTimestamp] = React.useState(0);
    const [finderPosition, setFinderPosition] = React.useState(0);

    const [program, setProgram] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [numberOfMusics, setNumberOfMusics] = React.useState(-1);
    const [numberOfMusicsSaved, setNumberOfMusicsSaved] = React.useState(false);
    const [waveformat, setWaveformat] = React.useState([]);
    const [splits, setSplits] = React.useState([]);
    const [selectedSplitIndex, setSelectedSplitIndex] = React.useState(null);
    const [initialPosition, setInitialPosition] = React.useState(-1);
    const [seekerDragging, setSeekerDragging] = React.useState(false);

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [volume, setVolume] = React.useState(0.4);

    const [waveContainerWidth, setWaveContainerWidth] = React.useState(1000);


    const successSnackbarRef = useRef();
    const errorSnackbarRef = useRef();

    const theme = useTheme();

    useEffect(() => {
        programService.getProgram(programId).then(response => setProgram(response.data));
        musicService.getWaveFormat(programId).then(response => setWaveformat(response.data))
    }, []);


    const waveScale = waveContainerWidth / waveformat.length;

    const saveMusicCounting = () => {

        const splitsInMilliseconds = splits.map((split, index) => (split * 1000 * duration) / waveContainerWidth);

        let durations = []
        for (let i = 0; i < splitsInMilliseconds.length; ++i) {
            if (i !== splitsInMilliseconds.length - 1) {
                durations.push(splitsInMilliseconds[i + 1] - splitsInMilliseconds[i])
            } else {
                durations.push(1000 * duration - splitsInMilliseconds[i])
            }
        }

        const data = {durations: durations, program: programId};

        musicService.autoAnalyseMusicWithStartStop(programId, data).then(response => {
                if (response.status === 200) {
                    setMusics(response.data);
                    if (successSnackbarRef.current) successSnackbarRef.current.openSnackbar("Success");
                    setOpen(false);
                } else {
                    if (errorSnackbarRef.current) errorSnackbarRef.current.openSnackbar("Fail");

                }
            }
        )
    }

    const handleOnListen = (timestamp_, force = false, forceContainerWidth = null) => {
        let x = forceContainerWidth === null ? waveContainerWidth : forceContainerWidth;
        if (!seekerDragging || force) {
            setTimestamp(timestamp_);
            if (audioPlayerRef.current && audioPlayerRef.current.audioEl.current) {
                setFinderPosition((timestamp_ / audioPlayerRef.current.audioEl.current.duration) * x);
            }
        }
    }

    const handleSetNumberMusicsSaved = () => {
        let splits_ = []
        for (let i = 0; i < numberOfMusics; ++i) {
            splits_.push(5 + (waveContainerWidth / numberOfMusics * i));
        }
        setSplits(splits_);
        setNumberOfMusicsSaved(true);
    }

    const handleSplitDragStart = (index) => {
        setSelectedSplitIndex(index);
        setInitialPosition(splits[index]);
    };

    const handleSplitDragEnd = (e) => {
        let _splits = [...splits];
        _splits[selectedSplitIndex] = initialPosition + e.target.x();
        e.target.setX(0)
        e.target.setY(0)
        setSplits(_splits)
        setSelectedSplitIndex(null);
    };

    const handleSplitDragMove = (index, newX, e) => {
        e.target.setY(0);
    };

    const handleSeekerDragEnd = (e) => {
        let timestamp_ = (e.target.x() / waveContainerWidth) * audioPlayerRef.current.audioEl.current.duration
        timestamp_ += timestamp;
        setSeekerDragging(false);
        handleOnListen(timestamp_, true);
        e.target.setX(0);
        audioPlayerRef.current.audioEl.current.currentTime = timestamp_;
        //   audioPlayerRef.current.audioEl.current.fastSeek(timestamp_);
    };

    const handleSeekerDragMove = (newX, e) => {
        e.target.setY(0)
    };

    const playSong = () => {
        if (audioPlayerRef.current && audioPlayerRef.current.audioEl.current) {
            audioPlayerRef.current.audioEl.current.play();
            setIsPlaying(true);
        }
    }

    const pauseSong = () => {
        if (audioPlayerRef.current && audioPlayerRef.current.audioEl.current) {
            audioPlayerRef.current.audioEl.current.pause();
            setIsPlaying(false);
        }
    }

    const handleOnCanPlay = () => {
        if (audioPlayerRef.current && audioPlayerRef.current.audioEl.current) {
            setDuration(audioPlayerRef.current.audioEl.current.duration);
        }
    }

    const handleVolumeChange = (e, newValue) => {
        setVolume(newValue);
    }

    const handleZoom = () => {
        if (waveContainerWidth === 1000) {
            setWaveContainerWidth(5000);
            setSplits(splits.map((element, index) => element * 5));
            handleOnListen(timestamp, false, 5000);
        } else {
            setWaveContainerWidth(1000);
            setSplits(splits.map((element, index) => element / 5));
            handleOnListen(timestamp, false, 1000);
        }
    }

    return (
        <>
            <StyledMenuItem
                onClick={e => setOpen(true)}
                isactive={false}>
                {t('detail.music.countMusic')}
            </StyledMenuItem>

            <Dialog open={open} onClose={e => setOpen(false)} maxWidth="lg">
                <DialogTitle>{t('detail.music.countMusic')}</DialogTitle>

                <DialogContent>
                    {numberOfMusics === -1 || !numberOfMusicsSaved ?
                        <>
                            <TextField autoFocus margin="dense" id="name" label={t('detail.music.numberOfSongs')}
                                       type="text" fullWidth
                                       variant="standard"
                                       sx={{marginBottom: "1.5em"}} onChange={e => setNumberOfMusics(e.target.value)}/>

                            <Button onClick={e => handleSetNumberMusicsSaved()} variant={"contained"}>Weiter</Button>
                        </>


                        : <>
                            <Typography sx={{marginBottom: "1em", maxWidth: "1000px"}}>
                                {t('detail.music.countMusicInfo')}
                            </Typography>

                            <CustomScrollbar>

                                <div style={{width: `${waveContainerWidth}px`, height: "310px"}}>

                                    <Stage width={waveContainerWidth} height={300}
                                           style={{backgroundColor: "lightgray"}}>

                                        <Layer>
                                            {waveformat.map((element, index) => {
                                                if (index + 1 < waveformat.length) {
                                                    const nextElement = waveformat[index + 1];
                                                    return (
                                                        <Line
                                                            fill={"#333"} stroke={"#333"} strokeWidth={1}
                                                            key={`wave-${index}`}
                                                            points={[index * waveScale, 150 + element * 150, (index + 1) * waveScale, 150 + nextElement * 150]}
                                                        />
                                                    );

                                                } else {
                                                    return null;
                                                }
                                            })}


                                            {splits.map((split, index) => (
                                                <React.Fragment key={`line-${index}`}>
                                                    <Line
                                                        fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth={4}
                                                        points={[split, 5, split, 295]}
                                                        draggable
                                                        onDragStart={() => handleSplitDragStart(index)}
                                                        onDragEnd={e => handleSplitDragEnd(e)}
                                                        onDragMove={(e) => {
                                                            if (selectedSplitIndex === index) {
                                                                handleSplitDragMove(index, e.target.x(), e);
                                                            }
                                                        }}
                                                        onMouseEnter={() => document.body.style.cursor = 'ew-resize'}
                                                        onMouseLeave={() => document.body.style.cursor = 'default'}
                                                    />
                                                </React.Fragment>
                                            ))}


                                            {audioPlayerRef.current
                                                ? <Line
                                                    draggable
                                                    points={[finderPosition, 5, finderPosition, 295]}
                                                    stroke="blue"
                                                    strokeWidth={5}
                                                    onDragStart={e => setSeekerDragging(true)}
                                                    onDragEnd={e => handleSeekerDragEnd(e)}
                                                    onDragMove={e => handleSeekerDragMove(e.target.x(), e)}
                                                    lineCap="round"
                                                    onMouseEnter={() => document.body.style.cursor = 'ew-resize'}
                                                    onMouseLeave={() => document.body.style.cursor = 'default'}
                                                /> : null}

                                        </Layer>
                                    </Stage>
                                </div>
                            </CustomScrollbar>


                            <div style={{
                                height: "80px",
                                width: "100%",
                                background: "#222",
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <span style={{width: "25%", paddingLeft: "2em"}}>
                                    <Typography
                                        sx={{color: "white"}}>{formatTime(timestamp)} / {formatTime(duration)}</Typography>
                                </span>


                                <span style={{width: "50%", padding: "1em", textAlign: "center"}}>

                                    {!isPlaying
                                        ? <IconButton aria-label="previous song" sx={{color: "white"}}
                                                      onClick={e => playSong()}>
                                            <PlayArrowIcon fontSize="large"/>
                                        </IconButton>

                                        : <IconButton aria-label="previous song" sx={{color: "white"}}
                                                      onClick={e => pauseSong()}>
                                            <PauseIcon fontSize="large"/>
                                        </IconButton>}
                                    <IconButton aria-label="previous song" sx={{color: "white"}}
                                                onClick={e => handleZoom()}>

                                        {
                                            waveContainerWidth === 1000
                                                ? <ZoomInIcon fontSize="large"/>
                                                : <ZoomOutIcon fontSize="large"/>
                                        }
                                    </IconButton>
                                </span>


                                <span style={{width: "25%", paddingRight: "2em", textAlign: "center"}}>
                                    <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
                                        <IconButton aria-label="previous song" sx={{color: "white"}}>
                                            <VolumeDownIcon fontSize="large"/>
                                        </IconButton>
                                        <Slider aria-label="Volume" min={0} max={1} step={0.01} value={volume}
                                                onChange={handleVolumeChange}/>
                                        <IconButton aria-label="previous song" sx={{color: "white"}}>
                                            <VolumeUpIcon fontSize="large"/>
                                        </IconButton>
                                    </Stack>
                                </span>

                            </div>
                        </>


                    }

                    <ReactAudioPlayer
                        ref={audioPlayerRef}
                        style={{width: "90%", marginLeft: "5%", marginRight: "5%", display: "none"}}
                        src={program ? program.music : null}
                        controls
                        onCanPlay={e => handleOnCanPlay()}
                        volume={volume}
                        listenInterval={20}
                        onListen={(e) => handleOnListen(e)}
                    />

                </DialogContent>

                <DialogActions sx={{marginBottom: "1em"}}>
                    <Button onClick={e => setOpen(false)}>{t('dashboard.cancel')}</Button>
                    <Button variant={"contained"} onClick={e => saveMusicCounting()}>{t('dashboard.save')}</Button>
                </DialogActions>

            </Dialog>

            <SuccessSnackbar horizontal={"left"} ref={successSnackbarRef}></SuccessSnackbar>
            <ErrorSnackbar ref={errorSnackbarRef}></ErrorSnackbar>

        </>


    )
}


export default MusicCountDialog