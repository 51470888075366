import axios from 'axios';
import {requestService} from "./RequestService";

export const userService = {
    getUser, updateUser, createUser, deleteUser
}


async function getUser() {
    const url = process.env.REACT_APP_USER_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}


async function updateUser(user) {
    const url = process.env.REACT_APP_USER_URL
    let config = requestService.authRequestConfig();
    return await axios.put(url, user, config);
}

async function createUser(user) {
    const url = process.env.REACT_APP_USER_URL + "register/"
    let config = {};
    return await axios.post(url, user, config);
}

async function deleteUser() {
    const url = process.env.REACT_APP_USER_URL
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}