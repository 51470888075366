import {FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {isNumber} from '../../../utils/DefaultHelpers';
import {useTranslation} from "react-i18next";


const ChoreographiesRightContainerDrills = ({
                                                choreographyTactGroup,
                                                sendDrillToServer,
                                                selectedChoreographyDrill,
                                                performers,
                                                setSelectedChoreographyTactGroup
                                            }) => {

    const {t} = useTranslation();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 250,
            },
        },
    };

    return (
        <>
            <Typography variant={"h6"} sx={{marginBottom: ".6em"}}>{t('detail.choreography.drills')}</Typography>
            <TableContainer>
                <Table size="small" sx={{border: "none"}}>
                    <TableBody>
                        {choreographyTactGroup.choreography_group_object.choreographyDrills.map((item, index) => (
                            <TableRow key={`drill-row-${index}`}>

                                <TableCell sx={{padding: 0, paddingTop: ".8em", border: "none", paddingRight: ".2em", width: "80%"}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{t('detail.choreography.performer')}</InputLabel>
                                        <Select value={item.performer ? item.performer : 0} label="Turner" size="small" MenuProps={MenuProps}
                                                sx={{fontWeight: (selectedChoreographyDrill && selectedChoreographyDrill.id === item.id ? "bold" : ""),}}
                                                onChange={(e) => sendDrillToServer({...item, performer: e.target.value})}>
                                            {performers.map((performer, i) => (
                                                <MenuItem key={`perf-sel-${i}`} value={performer.id}>{performer.name}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </TableCell>

                                <TableCell sx={{padding: 0, paddingTop: ".8em", border: "none", paddingRight: ".2em", width: "20%"}}>
                                    <TextField value={item.delay} size={"small"} InputLabelProps={{style: {fontSize: '0.9m'}}}
                                               label={t('detail.choreography.delay')}
                                               onChange={e => {
                                                   isNumber(e.target.value) ?
                                                       setSelectedChoreographyTactGroup(prevState => ({
                                                           ...prevState,
                                                           choreography_group_object: {
                                                               ...prevState.choreography_group_object,
                                                               choreographyDrills:
                                                                   prevState.choreography_group_object.choreographyDrills.map((item_, index) => (
                                                                       item_.id === item.id ? {...item_, delay: e.target.value,} : item_
                                                                   ))
                                                           }
                                                       })) : console.log("Only numbers allowed")
                                               }}
                                               onBlur={e => sendDrillToServer(item)}>
                                    </TextField>
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ChoreographiesRightContainerDrills