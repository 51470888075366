import React, {useState} from "react";
import {authenticationService} from '../../services/AuthenticationService';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, Collapse, TextField} from "@mui/material";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {useTheme} from "@mui/material/styles";


/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const PerformPasswordReset = () => {

    const {t} = useTranslation();

    const [newPassword, setNewPassword] = useState("");
    const [token, setToken] = useState("");

    let navigate = useNavigate();

    const [showInvalidTokenMessage, setShowInvalidTokenMessage] = useState(false);
    const [showInvalidPasswordMessage, setShowInvalidPasswordMessage] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

    const searchParams = new URLSearchParams(document.location.search)

    const theme = useTheme();

    const handleSubmit = e => {
        e.preventDefault()
        authenticationService.performPasswordRest(newPassword, searchParams.get("token"))
            .then(response => {
                if (response.status === 200) {
                    setShowInvalidTokenMessage(false);
                    setShowInvalidPasswordMessage(false);
                    setResetPasswordSuccess(true);

                } else if (response.status === 400) {
                    setShowInvalidTokenMessage(false);
                    setShowInvalidPasswordMessage(true);
                    setResetPasswordSuccess(false);
                } else {
                    setShowInvalidTokenMessage(true);
                    setShowInvalidPasswordMessage(false);
                    setResetPasswordSuccess(false);
                }
            })
    }

    return (
        <div style={{height: "100%", display: "flex", alignItems: "center", overflow: "hidden"}}>
            <div style={{width: "37%", height: "100%"}}>
                <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderTopRightRadius: "0.5em",
                        borderBottomRightRadius: "0.5em"
                    }}
                    alt="Login Image"
                    src="/mainHeader.jpg"
                />
            </div>

            <div style={{width: "63%"}}>
                <div style={{paddingLeft: "15em", paddingRight: "15em"}}>
                    {
                        resetPasswordSuccess
                            ? <>
                                <h1>{t('performResetPassword.successTitle')}</h1>
                                <p>{t('performResetPassword.successMessage')}</p>
                                <Button
                                    style={{marginBottom: "1em", marginTop: "1em"}}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={e => navigate("/login")}
                                    type="submit">{t('resetPassword.backToLogin')}
                                </Button>
                            </>

                            : <>
                                <LanguageSwitcher color={theme.palette.secondary.main}/>
                                <h1>{t('performResetPassword.description')}</h1>
                                <form onSubmit={handleSubmit}>

                                    <TextField
                                        style={{marginBottom: "1em"}}
                                        fullWidth={true}
                                        variant="standard"
                                        autoComplete={"new-password"}
                                        type="password"
                                        label={t('performResetPassword.password')}
                                        onChange={(event) => setNewPassword(event.target.value)}
                                    />

                                    <Button
                                        style={{marginBottom: "1em", marginTop: "1em"}}
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        type="submit">{t('performResetPassword.save')}
                                    </Button>

                                    <div style={{color: "gray", textAlign: "center", marginBottom: "1em"}}>
                                        <a style={{color: theme.palette.secondary.main, textDecoration: "none"}} href={"#"} onClick={e => navigate("/login")}>
                                            {t("resetPassword.backToLogin")}
                                        </a>
                                    </div>

                                    <Collapse in={showInvalidTokenMessage} sx={{width: "100%", marginTop: "2em"}}>
                                        <Alert severity="error"> {t('performResetPassword.invalidToken')}</Alert>
                                    </Collapse>
                                    <Collapse in={showInvalidPasswordMessage} sx={{width: "100%", marginTop: "2em"}}>
                                        <Alert severity="error">
                                            {t('performResetPassword.invalidPasswordMessage')}
                                            <ul style={{marginBottom: 0}}>
                                                <li>{t('performResetPassword.requirement1')}</li>
                                                <li>{t('performResetPassword.requirement2')}</li>
                                                <li>{t('performResetPassword.requirement3')}</li>
                                                <li>{t('performResetPassword.requirement4')}</li>
                                            </ul>
                                        </Alert>
                                    </Collapse>

                                </form>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default PerformPasswordReset;
