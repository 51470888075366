import React, {useState} from "react";
import {authenticationService} from '../../services/AuthenticationService';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Box, Button, TextField} from "@mui/material";
import {userService} from "../../services/UserService";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {useTheme} from "@mui/material/styles";


/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const Register = () => {

    const {t} = useTranslation();

    const [credentials, setCredentials] = useState({email: "", name: "", password: ""});
    const [errors, setErrors] = useState({});

    let navigate = useNavigate();

    const theme = useTheme();


    const handleSubmit = e => {
        e.preventDefault()
        userService.createUser(credentials).then((response) => {
            if(response.status === 200) {
                setErrors([]);
                authenticationService.login(credentials.email, credentials.password)
                    .then(response => {
                        navigate("/dashboard");
                    })
            } else {
                setErrors(response.data)
            }
        });
    }

    return (
        <div style={{height: "100%", display: "flex", alignItems: "center", overflow: "hidden"}}>

            <div style={{width: "37%", height: "100%"}}>
                <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderTopRightRadius: "0.5em",
                        borderBottomRightRadius: "0.5em"
                    }}
                    alt="Login Image"
                    src="/mainHeader.jpg"
                />

            </div>

            <div style={{width: "63%"}}>
                <div style={{paddingLeft: "15em", paddingRight: "15em"}}>
                    <LanguageSwitcher color={theme.palette.secondary.main}/>

                    <h1>{t('register.title')}</h1>
                    <p>{t('register.description')}</p>

                    <form onSubmit={handleSubmit}>

                        <TextField
                            error={"name" in errors}
                            helperText={errors["name"]}
                            style={{marginBottom: "1em"}}
                            fullWidth={true}
                            variant="standard"
                            label={t('register.username')}
                            onChange={(event) => setCredentials({
                                ...credentials, name: event.target.value,
                            })}
                        />

                        <TextField
                            error={"email" in errors}
                            helperText={errors["email"]}
                            style={{marginBottom: "1em"}}
                            fullWidth={true}
                            variant="standard"
                            label={t('register.email')}
                            onChange={(event) => setCredentials({
                                ...credentials, email: event.target.value,
                            })}
                        />

                        <TextField
                            error={"password" in errors}
                            helperText={errors["password"]}
                            style={{marginBottom: "1em"}}
                            fullWidth={true}
                            type="password"
                            variant="standard" autoComplete="new-password"
                            label={t('register.password')}
                            onChange={(event) => setCredentials({
                                ...credentials, password: event.target.value
                            })}
                        />
                        <Button
                            style={{marginBottom: "1em", marginTop: "1em"}}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            type="submit">{t('register.sendLink')}
                        </Button>

                        <div style={{color: "gray", textAlign: "center", marginBottom: "1em"}}>
                            <a style={{color: theme.palette.secondary.main, textDecoration: "none"}} href={"#"} onClick={e => navigate("/login")}>
                                {t("register.backToLogin")}</a>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
}

export default Register;
