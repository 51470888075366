import _ from './AvatarListItem.css'
import {Avatar, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";

const AvatarListItem = ({text, id, onClick, image}) => {

    return (
          <ListItem key={id} disablePadding>
            <ListItemButton onClick={onClick}>
              <ListItemAvatar>
                <Avatar
                    alt={text}
                    src={image ? process.env.REACT_APP_BASE_URL + image : `/static/images/avatar/.jpg`}
                />
              </ListItemAvatar>
              <ListItemText primary={text}/>
            </ListItemButton>
          </ListItem>
    )
}

export default AvatarListItem
