export const getTimeRangeOfChoreographyTactGroup = (choreographyTactGroup) => {

    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;

    const music = choreographyTactGroup.choreography_group_object.music_object;
    for (let choreographyDetail of choreographyTactGroup.choreographyDetail) {
        let beatsSinceBegging = music.tact * (choreographyTactGroup.tact_number - 1) + (choreographyDetail.beat - 1);
        let timestamp = music.start_time + beatsSinceBegging * music.tact_duration;
        if (timestamp < min) {
            min = timestamp;
        }

        if (timestamp > max) {
            max = timestamp;
        }
    }

    return {min, max}
}

export const getNumberOfTactsInMusic = (music) => {
    console.log((music.end_time - music.start_time) / (music.tact_duration * music.tact))
    return (Math.ceil((music.end_time - music.start_time) / (music.tact_duration * music.tact)));
}