import {useParams} from "react-router-dom";
import ProgramHeader from "../../../components/ProgramHeader";
import React, {useEffect, useRef, useState} from "react";
import ResizeableComponent from "../../../components/ResizeableComponent";
import {performerService} from "../../../services/PerformerService";
import ProgramEditHeader from "../../../components/ProgramEditHeader";
import DefaultContentContainer from "../../../components/DefaultContentContainer";
import AvatarListItem from "../../../components/AvatarListItem";
import PerformerRightContainer from "./PerformerRightContainer";
import PerformerActionBar from "./PerformerActionBar";
import ErrorSnackbar from "../../../components/ErrorSnackbar";
import PerformerMiddleContainer from "./PerformerMiddleContainer";

const PerformerMain = () => {

    const [selectedPerformer, setSelectedPerformer] = React.useState(null);
    const [performers, setPerformers] = React.useState([]);

    let {programId} = useParams()

    const snackbarRef = useRef();
    const resizeableComponentRef = useRef();
    const [errors, setErrors] = useState({});

    const fetchData = async () => {
        return await performerService.getAllPerformers();
    }

    useEffect(() => {
        fetchData().then(performerRequest => {
            setPerformers(performerRequest["data"].filter(x => x.program.toString() === programId));
        });
    }, []);


    const createPerformer = async () => {
        const newPerformer = {name: "", program: programId}
        let performerResponse = await performerService.createPerformer(newPerformer);
        if (performerResponse.status === 201) {
            setSelectedPerformer(performerResponse.data);
            const performers_ = performers.slice();
            performers_.push(performerResponse.data);
            setPerformers(performers_);
        } else {
            snackbarRef.current.openSnackbar("Turner konnte nicht hinzugefügt werden.");
        }
    }

    const updatePerformer = async (performer) => {
        const updatedPerformerResponse = await performerService.updatePerformer(performer);

        if (updatedPerformerResponse.status === 200) {
            const performers_ = performers.slice();
            const i = performers_.findIndex(x => x.id === performer.id);
            performers_[i] = updatedPerformerResponse["data"];
            setPerformers(performers_);
            setSelectedPerformer(updatedPerformerResponse.data);
            setErrors({})
        } else {
            setErrors(updatedPerformerResponse.data)
        }
    }

    const deletePerformer = async (performerId) => {
        if (selectedPerformer) {
            await performerService.deletePerformer(performerId);
            let performers_ = performers.slice();
            performers_ = performers_.filter(x => x.id !== performerId);
            setPerformers(performers_);
            setSelectedPerformer(null);
        }
    }


    return (
        <DefaultContentContainer onKeyDown={() => null}>
            <ProgramEditHeader>
                <ProgramHeader programId={programId} selectedMenu={"performer"}/>
                <PerformerActionBar addPerformer={createPerformer}/>
            </ProgramEditHeader>

            <main>
                <ResizeableComponent ref={resizeableComponentRef}>

                    <div style={{marginTop: "10px"}}>
                        {performers.map((performer, i) => {
                            return (
                                <AvatarListItem text={performer.name} image={performer.image} key={i}
                                                onClick={e => setSelectedPerformer(performer)}></AvatarListItem>
                            )
                        })}
                    </div>

                    <PerformerMiddleContainer
                        selectedPerformer={selectedPerformer}
                    />

                    <PerformerRightContainer
                        errors={errors}
                        performer={selectedPerformer}
                        setSelectedPerformer={setSelectedPerformer}
                        deletePerformer={deletePerformer}
                        updatePerformer={updatePerformer}/>

                </ResizeableComponent>
            </main>
            <ErrorSnackbar ref={snackbarRef}/>

        </DefaultContentContainer>
    )
}

export default PerformerMain
