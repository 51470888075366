import i18n from 'i18next';
import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationDE from './assets/locales/de/translation.json';
import translationFR from './assets/locales/fr/translation.json';
import {initReactI18next} from 'react-i18next';
import {localStorageService} from "./services/LocalStorageService";


const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    },
    fr: {
        translation: translationFR
    }
};

export const availableLanguages = ["en", "de", "fr"]

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: localStorage.getItem('language'),
        fallbackLng: 'en',
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;
