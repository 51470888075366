import axios from 'axios';
import {localStorageService} from "./LocalStorageService";
import {requestService} from "./RequestService";

/**
 * This files provides function for all authentication needs.
 */
export const authenticationService = {
    login,
    logout,
    changePassword,
    requestPasswordRest,
    performPasswordRest,
    validatePasswordRest,
    refreshToken
};

async function login(login, password) {
    const config = {headers: {"Content-Type": "application/json"}};
    const data = {email: login, password: password};
    const url = process.env.REACT_APP_AUTHENTICATE_URL
    const response = await axios.post(url, data, config);

    if (response.status === 200) handleSuccessfulLogin(response.data);

    return response;
}

function handleSuccessfulLogin(data) {
    localStorage.setItem("access", data.access);
    localStorage.setItem("refresh", data.refresh);
    return data;
}


async function changePassword(old_password, password, password2) {
    const url = process.env.REACT_APP_CHANGE_PASSWORD_URL;
    const config = requestService.authRequestConfig();
    const data = {old_password: old_password, password: password, password2: password2};
    return await axios.put(url, data, config);
}

async function validatePasswordRest(token) {
    const data = {token: token};
    return axios.post(process.env.REACT_APP_VALIDATE_RESET_PASSWORD_URL, data, {headers: {"Content-Type": "application/json"}}).then((response) => {
        return [response.status];
    }).catch(error => console.error(error));
}

async function requestPasswordRest(email) {
    const data = {email: email};
    return axios.post(process.env.REACT_APP_REQUEST_RESET_PASSWORD_URL, data, {headers: {"Content-Type": "application/json"}}).then((response) => {
        return response;
    }).catch(error => console.error(error));
}

async function performPasswordRest(newPassword, token) {
    const data = {password: newPassword, token: token};
    return axios.post(process.env.REACT_APP_PERFORM_RESET_PASSWORD_URL, data, {headers: {"Content-Type": "application/json"}}).then((response) => {
        return response;
    }).catch(error => {
        return error;
    });
}

async function refreshToken() {
    const refreshToken = localStorage.getItem("refresh");
    const data = {refresh: refreshToken};
    return axios.post(process.env.REACT_APP_AUTH_REFRESH_URL, data, {headers: {"Content-Type": "application/json"}}).then((response) => {
        localStorage.setItem("access", response.data.access);
        return response;
    }).catch(error => {
        return error;
    });
}


function logout() {
    localStorageService.clear();
}
