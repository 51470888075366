import React from "react";
import {useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LanguageSwitcher from "./LanguageSwitcher";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";


const ProgramEditHeader = ({children}) => {

    let navigate = useNavigate();

    const Header = styled("header")(({theme}) => ({
        background: theme.palette.text.primary,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        height: "9%",
        color: theme.palette.background.default,
        fontSize: ".95rem"
    }))

    return (
        <Header>
            <div style={{width: "18%", marginLeft: "2%", display: "inline-block"}}>
                <div className="horiz-menu" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                    <IconButton aria-label="delete" sx={{color: "white"}} size="large" onClick={x => navigate(`/dashboard/`)}>
                        <GridViewIcon sx={{fontSize: "100%"}}/>
                    </IconButton>
                </div>
            </div>

            <div className={"innerHeader"}>
                {children}
            </div>

            <div style={{width: "18%", display: "inline-block", marginRight: "2%"}}>
                <div className="horiz-menu" style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                    <LanguageSwitcher/>

                    <IconButton aria-label="delete" sx={{color: "white"}} size="large" onClick={x => navigate(`/profile/`)}>
                        <PersonOutlineIcon sx={{fontSize: "100%"}}/>
                    </IconButton>
                </div>
            </div>

        </Header>
    )
}

export default ProgramEditHeader
