import React, {useState} from "react";
import {authenticationService} from '../../services/AuthenticationService';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Box, Button, TextField} from "@mui/material";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {useTheme} from "@mui/material/styles";


/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const PasswordReset = () => {

    const {t} = useTranslation();

    const [credentials, setCredentials] = useState({
        email: "",
    });

    let navigate = useNavigate();

    const [requestPasswordPerformed, setRequestPasswordPerformed] = useState(false);

    const theme = useTheme();

    const handleSubmit = e => {
        e.preventDefault()
        authenticationService.requestPasswordRest(credentials.email)
            .then(response => {
                setRequestPasswordPerformed(true);
            })
    }

    return (
        <div style={{height: "100%", display: "flex", alignItems: "center", overflow: "hidden"}}>
            <div style={{width: "37%", height: "100%"}}>
                <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderTopRightRadius: "0.5em",
                        borderBottomRightRadius: "0.5em"
                    }}
                    alt="Login Image"
                    src="/mainHeader.jpg"
                />
            </div>

            <div style={{width: "63%"}}>
                <div style={{paddingLeft: "15em", paddingRight: "15em"}}>

                    {requestPasswordPerformed
                        ? <>
                            <h1>{t('resetPassword.title')}</h1>
                            <p>{t('resetPassword.performedDescription')}</p>
                            <Button
                                style={{marginBottom: "1em", marginTop: "1em"}}
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                onClick={e => navigate("/login")}
                                type="submit">{t('resetPassword.backToLogin')}
                            </Button>
                        </>
                        : <>
                            <LanguageSwitcher color={theme.palette.secondary.main}/>
                            <h1>{t('resetPassword.title')}</h1>
                            <p>{t('resetPassword.description')}</p>
                            <form onSubmit={handleSubmit}>

                                <TextField
                                    style={{marginBottom: "1em"}}
                                    fullWidth={true}
                                    variant="standard"
                                    label={t('resetPassword.email')}
                                    onChange={(event) => setCredentials({
                                        email: event.target.value
                                    })}
                                />

                                <Button
                                    style={{marginBottom: "1em", marginTop: "1em"}}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    type="submit">{t('resetPassword.sendLink')}
                                </Button>

                                <div style={{color: "gray", textAlign: "center", marginBottom: "1em"}}>
                                    <a style={{color: theme.palette.secondary.main, textDecoration: "none"}} href={"#"} onClick={e => navigate("/login")}>
                                        {t("resetPassword.backToLogin")}</a>
                                </div>

                            </form>
                        </>

                    }


                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
