import {useParams} from "react-router-dom";
import ProgramHeader from "../../../components/ProgramHeader";
import React, {useEffect, useRef, useState} from "react";
import ResizeableComponent from "../../../components/ResizeableComponent";
import ProgramEditHeader from "../../../components/ProgramEditHeader";
import DefaultContentContainer from "../../../components/DefaultContentContainer";
import ChoreographiesMiddleEditContainer from "./ChoreographiesMiddleEditContainer";
import {installationDrawingService} from "../../../services/InstallationDrawingService";
import ChoreographiesActionBar from "./ChoreographiesActionBar";
import {choreographyService} from "../../../services/ChoreographyService";
import ChoreographiesLeftContainer from "./ChoreographiesLeftContainer";
import {musicService} from "../../../services/MusicService";
import ChoreographiesRightContainer from "./ChoreographiesRightContainer";
import {performerService} from "../../../services/PerformerService";
import {programService} from "../../../services/ProgramService";
import ChoreographiesMiddleRenderContainer from "./ChoreographiesMiddleRenderContainer";


const ChoreographiesMain = () => {

    let {programId} = useParams()

    const renderPlayerRef = useRef(null);

    const [addDynamicDrillActive, setAddDynamicDrillActive] = React.useState(false);
    const [addStaticDrillActive, setAddStaticDrillActive] = React.useState(false);
    const [showAvailablePerformers, setShowAvailablePerformers] = React.useState(false);

    const [installationDrawings, setInstallationDrawings] = React.useState([]);
    const [choreographyTactGroups, setChoreographyTactGroups] = React.useState([]);
    const [musics, setMusics] = React.useState([]);
    const [performers, setPerformers] = React.useState([]);
    const [program, setProgram] = useState([]);

    const [isRenderView, setIsRenderView] = React.useState(false);
    const [renderTimestamp, setRenderTimestamp] = React.useState(0);

    const [selectedChoreographyDrill, setSelectedChoreographyDrill] = React.useState(null);
    const [selectedChoreographyTactGroup, setSelectedChoreographyTactGroup] = React.useState(null);

    const nullPerformer = {id: 0, name: "???"}

    const modeChange = (timestamp) => {
        setRenderTimestamp(timestamp / 1000);
        setIsRenderView(!isRenderView);
    }

    const createChoreographyGroup = async (choreographyGroup, tactNumber) => {
        const createdChoreographyGroupResponse = await choreographyService.createChoreographyGroup(choreographyGroup);
        const choreographyTactGroup = {"tact_number": tactNumber, "choreography_group": createdChoreographyGroupResponse["data"].id}
        const createdChoreographyTactGroupResponse = await choreographyService.createChoreographyTactGroup(choreographyTactGroup);
        let choreographyTactGroups_ = choreographyTactGroups.slice();

        choreographyTactGroups_.push(createdChoreographyTactGroupResponse["data"])
        const i = choreographyTactGroups_.length - 1;
        setSelectedChoreographyTactGroup(choreographyTactGroups_[i]);
        setChoreographyTactGroups(choreographyTactGroups_);
        setAddDynamicDrillActive(false);
    }

    const updateChoreographyGroup = async (choreographyGroup) => {
        const updatedChoreographyGroupResponse = await choreographyService.updateChoreographyGroup(choreographyGroup);
        choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId)));
    }


    const createChoreographyDetail = async (newChoreographyDetail) => {
        if (selectedChoreographyTactGroup) {
            const createdChoreographyDetail = await choreographyService.createChoreographyDetail(newChoreographyDetail);

            setChoreographyTactGroups((prevChoreographyTactGroups) => {
                const updatedTactGroups = prevChoreographyTactGroups.map((group) =>
                    group.id === selectedChoreographyTactGroup.id
                        ? {...group, choreographyDetail: [...group.choreographyDetail, createdChoreographyDetail.data]} : group
                );
                setSelectedChoreographyTactGroup(updatedTactGroups.find((group) => group.id === selectedChoreographyTactGroup.id));
                return updatedTactGroups;
            });
        }
    };


    const updateChoreographyDetail = async (choreographyDetail) => {
        const updatedChoreographyDetailResponse = await choreographyService.updateChoreographyDetail(choreographyDetail);
        let choreographyTactGroups_ = choreographyTactGroups.slice();
        const i = choreographyTactGroups_.findIndex(x => x.id === choreographyDetail.choreography_tact_group);
        const i2 = choreographyTactGroups_[i]["choreographyDetail"].findIndex(x => x.id === choreographyDetail.id);
        choreographyTactGroups_[i]["choreographyDetail"][i2] = updatedChoreographyDetailResponse["data"];
        setChoreographyTactGroups(choreographyTactGroups_);
    }

    const deleteChoreographyDetail = async (choreographyDetailId) => {
        if (selectedChoreographyTactGroup) {
            await choreographyService.deleteChoreographyDetail(choreographyDetailId);
            let choreographyTactGroups_ = choreographyTactGroups.slice();
            const i = choreographyTactGroups_.findIndex(x => x.id === selectedChoreographyTactGroup.id);
            const i2 = choreographyTactGroups_[i].choreographyDetail.findIndex(x => x.id === choreographyDetailId);
            choreographyTactGroups_[i].choreographyDetail.splice(i2, 1);
            setSelectedChoreographyTactGroup(choreographyTactGroups_[i]);
            setChoreographyTactGroups(choreographyTactGroups_);
        }
    }

    const createChoreographyTactGroup = async (choreographyTactGroup) => {
        if (selectedChoreographyTactGroup) {
            let createdChoreographyDrillsResponse = await choreographyService.createChoreographyTactGroup(choreographyTactGroup);
            let choreographyTactGroups_ = choreographyTactGroups.slice();
            choreographyTactGroups_.push(createdChoreographyDrillsResponse["data"]);
            setSelectedChoreographyTactGroup(createdChoreographyDrillsResponse["data"]);
            setChoreographyTactGroups(choreographyTactGroups_);
        }
    }

    const updateChoreographyTactGroup = async (choreographyTactGroup) => {
        const updatedChoreographyTactGroupResponse = await choreographyService.updateChoreographyTactGroup(choreographyTactGroup);
        const choreographyTactGroups_ = choreographyTactGroups.slice();
        const i = choreographyTactGroups_.findIndex(x => x.id === choreographyTactGroup.id);
        choreographyTactGroups_[i] = updatedChoreographyTactGroupResponse["data"];
        setChoreographyTactGroups(choreographyTactGroups_);
        setSelectedChoreographyTactGroup(updatedChoreographyTactGroupResponse.data);
    }

    const deleteChoreographyTactGroup = async () => {
        if (selectedChoreographyTactGroup) {
            await choreographyService.deleteChoreographyTactGroup(selectedChoreographyTactGroup.id);
            const choreographyTactGroups_ = choreographyTactGroups.slice().filter(x => x.id !== selectedChoreographyTactGroup.id);
            const sameTactGroupItems = choreographyTactGroups_.filter(x => x.choreography_group === selectedChoreographyTactGroup.choreography_group);
            if (sameTactGroupItems.length === 0) {
                await choreographyService.deleteChoreographyGroup(selectedChoreographyTactGroup.choreography_group);
            }
            setChoreographyTactGroups(choreographyTactGroups_);
            setSelectedChoreographyTactGroup(null);
        }
    }

    const createChoreographyDrill = async (choreographyDrill) => {
        if (selectedChoreographyTactGroup) {
            let createdChoreographyDrillsResponse = await choreographyService.createChoreographyDrill(choreographyDrill);

            choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => {
                    setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId));
                    let selectedChoreographyTactGroup_ = {...selectedChoreographyTactGroup};
                    selectedChoreographyTactGroup_.choreography_group_object.choreographyDrills.push(createdChoreographyDrillsResponse["data"])
                    setSelectedChoreographyTactGroup(selectedChoreographyTactGroup_)
                }
            );
        }
    }

    const updateChoreographyDrill = async (choreographyDrill) => {
        if (choreographyDrill.performer === 0) choreographyDrill.performer = null;
        const updatedChoreographyDrillResponse = await choreographyService.updateChoreographyDrill(choreographyDrill);
        choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => {
                setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId));
                let selectedChoreographyTactGroup_ = {...selectedChoreographyTactGroup};
                let i = selectedChoreographyTactGroup_.choreography_group_object.choreographyDrills.findIndex(x => x.id === choreographyDrill.id);
                selectedChoreographyTactGroup_.choreography_group_object.choreographyDrills[i] = updatedChoreographyDrillResponse["data"];
                setSelectedChoreographyTactGroup(selectedChoreographyTactGroup_)
            }
        );
    }

    const deleteChoreographyDrill = async () => {
        if (selectedChoreographyDrill) {
            await choreographyService.deleteChoreographyDrill(selectedChoreographyDrill.id);
            choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => {
                setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId));
                const selectedChoreographyTactGroup_ = choreographyTactGroupResponse["data"].filter(x => x.id === selectedChoreographyTactGroup.id)
                setSelectedChoreographyTactGroup(selectedChoreographyTactGroup_[0])
                setSelectedChoreographyDrill(null);
            });
        }
    }


    useEffect(() => {
        installationDrawingService.getAllInstallationDrawings().then(installationDrawingsResponse => setInstallationDrawings(installationDrawingsResponse["data"].filter(x => x.program.toString() === programId)));
        musicService.getAllMusics().then(musicResponse => setMusics(musicResponse["data"].filter(x => x.program.toString() === programId)));
        performerService.getAllPerformers().then(performerResponse => setPerformers([...performerResponse["data"].filter(x => x.program.toString() === programId), nullPerformer]));
        choreographyService.getAllChoreographyTactGroups().then(choreographyTactGroupResponse => setChoreographyTactGroups(choreographyTactGroupResponse["data"].filter(x => x.choreography_group_object.music_object.program.toString() === programId)));
        programService.getProgram(programId).then(program => setProgram(program["data"]));

        if (isRenderView && renderPlayerRef.current) {
            renderPlayerRef.current.playSongAtSeconds(renderTimestamp);
        }
    }, [isRenderView]);


    const handleKeyDown = async (e) => {
        if (e.keyCode === 46) await deleteChoreographyDrill();
    }

    return (
        <DefaultContentContainer onKeyDown={(e) => handleKeyDown(e)}>
            <ProgramEditHeader>
                <ProgramHeader programId={programId} selectedMenu={"choreographies"}/>
                <ChoreographiesActionBar
                    showAvailablePerformers={showAvailablePerformers}
                    setShowAvailablePerformers={setShowAvailablePerformers}
                    setAddDynamicDrillActive={(activate) => setAddDynamicDrillActive(activate)}
                    addDynamicDrillActive={addDynamicDrillActive}
                    setAddStaticDrillActive={(activate) => setAddStaticDrillActive(activate)}
                    addStaticDrillActive={addStaticDrillActive}
                    selectedChoreographyTactGroup={selectedChoreographyTactGroup}
                />
            </ProgramEditHeader>
            <main>
                <ResizeableComponent>

                    <ChoreographiesLeftContainer
                        setSelectedChoreographyDrill={setSelectedChoreographyDrill}
                        createChoreographyGroup={createChoreographyGroup}
                        choreographyTactGroup={selectedChoreographyTactGroup}
                        setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                        choreographyTactGroups={choreographyTactGroups}
                        setChoreographyTactGroups={setChoreographyTactGroups}
                        musics={musics}
                    />

                    {
                        isRenderView
                            ? <ChoreographiesMiddleRenderContainer
                                installationDrawings={installationDrawings}
                                choreographyTactGroups={choreographyTactGroups}
                                src={program.music}
                                musics={musics}
                                ref={renderPlayerRef}
                                modeChange={modeChange}
                            />

                            : <ChoreographiesMiddleEditContainer
                                selectedChoreographyTactGroup={selectedChoreographyTactGroup}
                                setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                                installationDrawings={installationDrawings}
                                selectedChoreographyDrill={selectedChoreographyDrill}
                                setSelectedChoreographyDrill={setSelectedChoreographyDrill}
                                updateChoreographyDrill={updateChoreographyDrill}
                                createChoreographyDrill={createChoreographyDrill}
                                choreographyTactGroups={choreographyTactGroups}
                                addDynamicDrillActive={addDynamicDrillActive}
                                addStaticDrillActive={addStaticDrillActive}
                                showAvailablePerformers={showAvailablePerformers}
                            />
                    }

                    <ChoreographiesRightContainer
                        sendToServer={updateChoreographyGroup}
                        sendDetailToServer={updateChoreographyDetail}
                        createChoreographyDetail={createChoreographyDetail}
                        deleteChoreographyDetail={deleteChoreographyDetail}
                        sendDrillToServer={updateChoreographyDrill}
                        performers={performers}
                        updateChoreographyTactGroup={updateChoreographyTactGroup}
                        createChoreographyTactGroup={createChoreographyTactGroup}
                        deleteChoreographyTactGroup={deleteChoreographyTactGroup}
                        choreographyTactGroup={selectedChoreographyTactGroup}
                        setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                        selectedChoreographyDrill={selectedChoreographyDrill}
                        choreographyTactGroups={choreographyTactGroups}
                        isRenderView={isRenderView}
                        modeChange={modeChange}
                    />
                </ResizeableComponent>
            </main>
        </DefaultContentContainer>
    )
}

export default ChoreographiesMain
