import React from "react";
import {useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import LanguageSwitcher from "./LanguageSwitcher";
import {styled} from "@mui/material/styles";
const ProgramEditHeader = ({children}) => {

    let navigate = useNavigate();

    const Header = styled("header")(({theme}) => ({
        background: theme.palette.text.primary,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        height: "9%",
    }))


    return (
        <Header>
            <div style={{padding: "2%"}}>
                <IconButton aria-label="delete" sx={{color: "white"}} size="large" onClick={x => navigate(`/dashboard/`)}>
                    <GridViewIcon sx={{fontSize: "100%"}}/>
                </IconButton>
            </div>


            <div style={{padding: "2%"}}>
                <LanguageSwitcher/>

                <IconButton aria-label="delete" sx={{color: "white"}} size="large" onClick={x => navigate(`/profile/`)}>
                    <PersonOutlineIcon sx={{fontSize: "100%"}}/>
                </IconButton>
            </div>
        </Header>
    )
}

export default ProgramEditHeader
