import * as React from 'react';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultConfirmDialog from "../../../components/DefaultConfirmDialog";


const DeletePerformerDialog = ({mainAction}) => {

    const {t} = useTranslation();
    const dialogRef = React.useRef();

    const handleClickOpen = (e) => {
        if (dialogRef.current) dialogRef.current.openDialog(e);

    };

    return (
        <>
            <Button variant="outlined" color="error" sx={{"padding": "7px 0px", minWidth: "50%"}}
                    onClick={e => handleClickOpen(e)} startIcon={<DeleteIcon/>}>
                {t('delete')}
            </Button>

            <DefaultConfirmDialog
                ref={dialogRef}
                title={t('detail.performer.deletePerformerTitle')}
                description={t('detail.performer.deletePerformerDescription')}
                mainActionText={t('delete')}
                mainAction={mainAction}
            />
        </>
    );
}

export default DeletePerformerDialog