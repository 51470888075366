import _ from './AvatarListItem.css'
import {Avatar, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";

const DefaultListItem = ({text, id, onClick, selected}) => {

    return (
          <ListItem key={id} disablePadding>
              <ListItemButton selected={selected === true} onClick={onClick}>
              <ListItemText primary={text} sx={selected ? {fontWeight: "1000"} : {}}/>
            </ListItemButton>
          </ListItem>
    )
}

export default DefaultListItem
