import ReactAudioPlayer from 'react-audio-player';
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Typography} from "@mui/material";

const MusicMediaPlayer = forwardRef(({program, musics, setSelectedMusic, selectedMusic, setIsPlaying}, ref) => {

    const [currentNumber, setCurrentNumber] = useState("-");
    const [currentSong, setCurrentSong] = useState("-");
    const [volume, setVolume] = useState(0.5);
    const audioPlayerRef = useRef(null);

    const updateNumber = (e) => {
        const timeStamp = (e * 1000) - 140
        let currentMusics = musics.filter(music => music.start_time < timeStamp && music.end_time > timeStamp);

        if (currentMusics.length === 0) {
            setCurrentNumber("-")
            setCurrentSong("");

        } else {
            let currentMusic = currentMusics[0];
            let diff = Math.round((((timeStamp - currentMusic.start_time) / currentMusic.tact_duration))) % currentMusic.tact;

            setCurrentNumber((1 + diff).toString());
            setCurrentSong(currentMusic.title);
            if (selectedMusic === undefined || selectedMusic === null || selectedMusic.title !== currentMusic.title) setSelectedMusic(currentMusic)

        }
    }

    useImperativeHandle(ref, () => ({
        playSongAtSeconds: (timeInSeconds) => {
            if (audioPlayerRef.current) {
                const audioElement = audioPlayerRef.current.audioEl;
                if (audioElement) {
                    if (audioElement.current.readyState >= 2) {
                        audioElement.current.currentTime = timeInSeconds;
                  //      audioElement.current.fastSeek(timeInSeconds);
                        audioElement.current.play();
                    }
                }
            }
        },
        pauseSong: () => {
            const audioElement = audioPlayerRef.current.audioEl;
            if (audioElement) {
                if (audioElement.current.readyState >= 2) {
                    audioElement.current.pause();
                }
            }
        },
        isAudioPlaying : () => {
            const audioElement = audioPlayerRef.current.audioEl;
            if (audioElement.current) {
                return !audioElement.current.paused;
            }
            return false;
        }
    }));


    return (
        <div style={{height: "100%"}}>

            <div className={"centerFlexBox"}
                 style={{height: "85%", background: "lightgray"}}>
                <div style={{fontSize: "2.5em"}}>{currentSong}</div>
                <div style={{fontSize: "10em"}}>{currentNumber}</div>
            </div>

            <div className={"centerFlexBox"} style={{height: "15%", background: "#333333"}}>
                <ReactAudioPlayer
                    ref={audioPlayerRef}
                    style={{width: "90%", marginLeft: "5%", marginRight: "5%"}}
                    src={program.music}
                    volume={volume}
                    onVolumeChanged={(e) => setVolume(e.target.volume)}
                    controls
                    listenInterval={20}
                    onPlay={e => setIsPlaying(true)}
                    onPause={e => setIsPlaying(false)}
                    onListen={(e) => updateNumber(e)}
                />
            </div>

        </div>
    )
});

export default MusicMediaPlayer