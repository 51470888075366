import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {programService} from "../../services/ProgramService";
import {Alert, Typography} from "@mui/material";
import {forwardRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AddProgramDialog = forwardRef(({}, ref) => {

    const [open, setOpen] = React.useState(false);
    const [selectedSong, setSelectedSong] = useState(null);
    const [programTitle, setProgramTitle] = useState(null);
    const [showFailAlert, setShowFailAlert] = useState(false);

    const {t} = useTranslation();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedSong(file);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let navigate = useNavigate();

    const addProgramm = async () => {
        const program = {title: programTitle, music: selectedSong}
        let programResponse = await programService.createProgram(program);
        if (programResponse.status === 201) {
            navigate(`/program/${programResponse["data"].id}/equipments`);
        } else {
            setShowFailAlert(true);
            console.log(programResponse)
        }
    }

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>{t('dashboard.addProgram')}</Button>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>{t('dashboard.addProgram')}</DialogTitle>
                <DialogContent>
                    <TextField autoFocus margin="dense" id="name" label={t('dashboard.programTitle')} type="text" fullWidth variant="standard"
                               sx={{marginBottom: "1.5em"}} onChange={e => setProgramTitle(e.target.value)}/>
                    <div>
                        <Button variant="contained" component="label" sx={{marginBottom: ".4em"}}>
                            {t('dashboard.addMusic')}
                            <input type="file" hidden accept="audio/*" onChange={handleFileChange}/>
                        </Button>
                    </div>
                    <Typography sx={{marginBottom: "1em", color: "#666"}}>
                        {selectedSong && `${selectedSong.name}`}
                    </Typography>
                    {showFailAlert && <Alert severity="error" sx={{marginTop: "1em"}}>{t('dashboard.programSaveError')}</Alert>}
                </DialogContent>

                <DialogActions sx={{marginBottom: "1em"}}>
                    <Button onClick={handleClose} color={"secondary"}>{t('dashboard.cancel')}</Button>
                    <Button variant={"contained"} onClick={e => addProgramm()}>{t('dashboard.save')}</Button>
                </DialogActions>

            </Dialog>
        </>
    );
})

export default AddProgramDialog;
