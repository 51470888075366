import ProgramHeader from "../../components/ProgramHeader";
import React, {useEffect} from "react";
import DefaultContentContainer from "../../components/DefaultContentContainer";
import DefaultHeader from "../../components/DefaultHeader";
import {Alert, Button, Collapse, TextField, Typography} from "@mui/material";
import {userService} from "../../services/UserService";
import ProfileMenu from "./ProfileMenu";
import {useTranslation} from "react-i18next";


const ProfileOverview = ({}) => {

    const {t} = useTranslation();


    const [user, setUser] = React.useState(null);
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [failOpen, setFailOpen] = React.useState(false);

    useEffect(() => {
        userService.getUser().then(userResponse => setUser(userResponse["data"]));
    }, []);


    const updateUser = async (e) => {
        const updatedUserResponse = await userService.updateUser(user).then((response) => {
            if (response.status === 200) {
                setSuccessOpen(true);
                setFailOpen(false);
            } else {
                setSuccessOpen(false);
                setFailOpen(true);
            }
        })
    }

    return (
        <DefaultContentContainer onKeyDown={(e) => null}>

            <DefaultHeader>
                <ProgramHeader programId={-1} selectedMenu={"choreographies"}/>
            </DefaultHeader>

            <main>
                <div style={{padding: "4%"}}>

                    <div style={{paddingLeft: "3em", paddingBottom: "2em"}}>
                        <Typography variant="h2">
                            {t('profile.title')}
                        </Typography>
                    </div>

                    <div style={{display: "flex"}}>
                        <div style={{flexBasis: "25%"}}>
                            <div style={{borderRight: "1px solid lightGray", paddingLeft: "3em"}}>
                                <ProfileMenu/>
                            </div>
                        </div>

                        <div style={{flexBasis: "75%"}}>
                            <div style={{padding: "3em"}}>

                                <Typography variant="h4" style={{paddingBottom: "1em"}}>
                                    {t('profile.overview')}
                                </Typography>


                                {user ?
                                    <>
                                        <TextField value={user.name} size={"small"} label={t('profile.username')}
                                                   InputLabelProps={{style: {fontSize: '0.9m'}}}
                                                   onChange={e => setUser({...user, name: e.target.value})}
                                                   sx={{width: "35%", marginBottom: ".8em", marginRight: "1%"}}
                                        />

                                        <TextField value={user.email} size={"small"} label={t('profile.email')}
                                                   InputLabelProps={{style: {fontSize: '0.9m'}}}
                                                   onChange={e => setUser({...user, email: e.target.value})}
                                                   sx={{width: "35%", marginBottom: ".8em", marginRight: "1%"}}
                                        />

                                        <Collapse in={failOpen} sx={{width: "71%"}}>
                                            <br/>
                                            <Alert severity="error">{t('profile.userSaveFail')}</Alert>
                                        </Collapse>

                                        <Collapse in={successOpen} sx={{width: "71%"}}>
                                            <br/>
                                            <Alert severity="success">{t('profile.userSaveSuccess')}</Alert>
                                        </Collapse>
                                        <br/>
                                        <Button variant={"contained"} onClick={e => updateUser(e)}>{t('profile.save')}</Button>
                                    </> : null


                                }
                            </div>
                        </div>

                    </div>
                </div>

            </main>
        </DefaultContentContainer>

    )
}

export default ProfileOverview