import {Button, Checkbox, Radio, TextField} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import RunCircleIcon from '@mui/icons-material/RunCircle';
import RunCircleOutlinedIcon from '@mui/icons-material/RunCircleOutlined';
import {useTranslation} from "react-i18next";
import {isNumeric, sleep} from "../../../utils/DefaultHelpers";
import {styled} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import {RunStopCheckbox} from "./components/RunStopCheckbox";

const ChoreographiesRightContainerDetails = ({
                                                 choreographyTactGroup,
                                                 sendDetailToServer,
                                                 deleteChoreographyDetail, snackbarRef,
                                                 setSelectedChoreographyTactGroup, readonly
                                             }) => {

    const {t} = useTranslation();

    const validateAndSendToServer = (item) => {
        if (isNumeric(item.beat)) {
            sendDetailToServer(item);
        } else {
            sleep(150).then(e => {
                snackbarRef.current.openSnackbar(t('detail.choreography.beatError'));
            })
        }
    }



    return (
        <>
            {choreographyTactGroup.choreographyDetail.map((item, index) => (
                <div key={`detail-${index}`} style={{display: "flex"}}>
                    <TextField value={item.beat} size={"small"} key={`cd${index}`} label={t('detail.choreography.beat')} disabled={readonly}
                               InputLabelProps={{style: {fontSize: '0.9m'}}} sx={{width: "19%", marginBottom: ".8em", marginRight: "1%"}}
                               onBlur={(e) => validateAndSendToServer(item)}
                               onChange={(e) => setSelectedChoreographyTactGroup({
                                   ...choreographyTactGroup,
                                   choreographyDetail: choreographyTactGroup.choreographyDetail.map((item, i) => i === index ? {
                                       ...item, beat: e.target.value
                                   } : item)
                               })}>
                    </TextField>
                    <TextField value={item.description} size={"small"} key={`cd2${index}`} label={t('detail.choreography.description')}
                               disabled={readonly}
                               InputLabelProps={{style: {fontSize: '0.9em'}}} sx={{width: "58%", marginRight: "1%", flexGrow: 1}}
                               onBlur={(e) => sendDetailToServer(item)}
                               onChange={(e) => setSelectedChoreographyTactGroup({
                                   ...choreographyTactGroup,
                                   choreographyDetail: choreographyTactGroup.choreographyDetail.map((item, i) => i === index ? {
                                       ...item, description: e.target.value
                                   } : item)
                               })}>
                    </TextField>


                    <RunStopCheckbox
                              icon={<RunCircleOutlinedIcon/>}
                              checkedIcon={< RunCircleIcon/>}
                              checked={item.run_flag}
                              onChange={e => sendDetailToServer({...item, run_flag: !item.run_flag})}
                    />

                    <div style={{flexGrow: 0}}>
                        <Button variant="outlined" color="primary" sx={{"height": "40px", width: "10px", minWidth: "10px"}} disabled={readonly}
                                onClick={(e) => deleteChoreographyDetail(item.id)}>
                            <RemoveIcon/>
                        </Button>
                    </div>


                </div>
            ))}
        </>
    );


}


export default ChoreographiesRightContainerDetails;