import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {programService} from "../../services/ProgramService";
import {Alert, IconButton} from "@mui/material";
import {forwardRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {useTranslation} from "react-i18next";
import Tooltip from '@mui/material/Tooltip';

const RenameProgramDialog = forwardRef(({origProgramTitle, programId, fetchData, successSnackbarRef}, ref) => {

    const {t} = useTranslation();


    const [open, setOpen] = React.useState(false);
    const [programTitle, setProgramTitle] = useState(origProgramTitle);
    const [showFailAlert, setShowFailAlert] = useState(false);


    const handleClickOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renameProgramm = async () => {
        if (programTitle === "") {
            setShowFailAlert(true);
            return;
        }
        const program = {id: programId, title: programTitle}
        let programResponse = await programService.updateProgram(program);
        if (programResponse.status === 200) {
            fetchData();
            setShowFailAlert(false);
            setOpen(false);
            successSnackbarRef.current.openSnackbar(t('dashboard.renameSuccess'));
        } else {
            setShowFailAlert(true);
        }
    }


    return (
        <>
            <Tooltip title={t('dashboard.renameProgram')}>
                <IconButton aria-label="Rename Program" size="small" onClick={e => handleClickOpen(e)}>
                    <EditOutlinedIcon fontSize="inherit"/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} onClick={e => e.stopPropagation()}
                    PaperProps={{style: {minWidth: '500px'}}}>
                <DialogTitle>{t('dashboard.renameProgram')}</DialogTitle>
                <DialogContent>
                    <TextField autoFocus value={programTitle} margin="dense" id="name" label={t('dashboard.programTitle')} type="text" fullWidth
                               variant="standard"
                               sx={{marginBottom: "1.5em"}} onChange={e => setProgramTitle(e.target.value)}/>
                    {showFailAlert && <Alert severity="error" sx={{marginTop: "1em"}}>{t('dashboard.programSaveFail')}</Alert>}
                </DialogContent>

                <DialogActions sx={{marginBottom: "1em"}}>
                    <Button onClick={handleClose}>{t('dashboard.cancel')}</Button>
                    <Button variant={"contained"} onClick={e => renameProgramm()}>{t('dashboard.save')}</Button>
                </DialogActions>

            </Dialog>
        </>
    );
})

export default RenameProgramDialog;
