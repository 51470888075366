import React from "react";
import {Table, TableBody, TableRow, TableCell, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useTranslation} from "react-i18next";
import {getNumberOfTactsInMusic} from "../../../utils/ChoreographyHelpers";




const ChoreographiesLeftContainer =
    ({
         createChoreographyGroup,
         musics, choreographyTactGroup, setSelectedChoreographyTactGroup, choreographyTactGroups, setChoreographyTactGroups,
         setSelectedChoreographyDrill
     }) => {

        const {t} = useTranslation();

        const calculateRowSpan = (music, musicTactCountsDict) => {
            const sum = Object.values(musicTactCountsDict).reduce((acc, count) => acc + (count - 1), 0);
            const n = getNumberOfTactsInMusic(music);
            return sum + n;
        }


        const getMusicTactCounts = (musicId) => {
            const musicTactCounts = {};
            let choreographyGroups_ = choreographyTactGroups.filter(x => x.choreography_group_object.music === musicId);

            choreographyGroups_.forEach((group) => {
                const musicTactKey = `${group.choreography_group_object.music}-${group.tact_number}`;
                musicTactCounts[musicTactKey] = (musicTactCounts[musicTactKey] || 0) + 1;
            });
            return musicTactCounts;
        }

        const getChoreographyOverviewList = () => {
            let displayList = [];

            for (const music of musics) {
                const musicTactCounts = getMusicTactCounts(music.id);
                displayList.push([
                    <TableCell key={music.title} rowSpan={calculateRowSpan(music, musicTactCounts)} sx={{"padding": 0}}>
                        <div style={{
                            "padding": 0,
                            "transform": "rotate(-90deg)",
                            "whiteSpace": "nowrap",
                        }}>
                            {music.title}
                        </div>
                    </TableCell>
                ]);

                const numberOfIterations = getNumberOfTactsInMusic(music);

                for (let i = 1; i <= numberOfIterations; i++) {
                    const key = `${music.id}-${i}`;
                    const rowSpan = musicTactCounts[key] || 1;
                    const choreographyGroupToAdd = {music: music.id, title: t('detail.choreography.defaultTitle')};
                    displayList[displayList.length - 1].push(
                        <>
                            <TableCell key={`${key}-i`} rowSpan={rowSpan} sx={{paddingLeft: ".5em", paddingRight: ".1em"}}>
                                <span>{`${i}`}</span>
                            </TableCell>
                            <TableCell key={`${key}-i-add`} rowSpan={rowSpan} sx={{paddingLeft: "0", marginRight: ".1em"}}>
                                <Button
                                    sx={{
                                        minWidth: "0.5em",
                                        width: "0.5em",
                                        minHeight: "0.5em",
                                        height: "0.5em",
                                        padding: "0.7em",
                                        marginLeft: ".5em"
                                    }}
                                    variant="outlined"
                                    color="primary" onClick={(e) => createChoreographyGroup(choreographyGroupToAdd, i)}>
                                    <AddIcon sx={{fontSize: "0.7em"}}/>
                                </Button>
                            </TableCell>
                        </>
                    )

                    let choreographyTactGroups_ = choreographyTactGroups.filter(x => x.choreography_group_object.music === music.id && x.tact_number === i);
                    for (let choreographyTactGroup of choreographyTactGroups_) {
                        displayList[displayList.length - 1].push(
                            <TableCell key={`${key}-group-${choreographyTactGroup.id}`} rowSpan={1} className={"hoverCell"}
                                       onClick={() => {
                                           setSelectedChoreographyTactGroup(choreographyTactGroup);
                                           setSelectedChoreographyDrill(null)
                                       }}>
                                <div>{choreographyTactGroup.choreography_group_object.title}</div>
                            </TableCell>
                        )
                        displayList.push([])
                    }
                    if (choreographyTactGroups_.length === 0) {
                        displayList[displayList.length - 1].push(
                            <TableCell key={key + "-null"} rowSpan={1}>
                                <div></div>
                            </TableCell>
                        );
                        displayList.push([])
                    }

                }
            }
            return displayList;
        }

        const displayList = getChoreographyOverviewList();
        return (
            <Table size="small">
                <TableBody>
                    {displayList.map((tableRow, rowIndex) => (
                        <TableRow key={"row-idx-" + rowIndex}>
                            {tableRow.map((tableCell, cellIndex) => (
                                tableCell
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );

    }
export default ChoreographiesLeftContainer;
