import {Rect} from "react-konva";
import React from "react";

const ChoreographiesDrillHandle = ({
                                       selectedChoreographyTactGroup,
                                       setSelectedChoreographyTactGroup,
                                       selectedChoreographyDrill,
                                       updateChoreographyDrill,
                                       firstIndexHandle, bothIndexHandle
                                   }) => {

    let x_ = firstIndexHandle ? "x1" : "x2";
    let y_ = firstIndexHandle ? "y1" : "y2";
    let size = bothIndexHandle ? 16 : 16;
    let halfSize = size / 2;
    return (

        <Rect
            x={selectedChoreographyDrill[x_] - halfSize} y={selectedChoreographyDrill[y_] - halfSize}
            height={size} width={size}
            fill={"white"} stroke={"#FF8C00"} strokeWidth={2}
            draggable={true}
            onDragEnd={(e) => updateChoreographyDrill(
                {
                    ...selectedChoreographyTactGroup.choreography_group_object.choreographyDrills.filter(x => x.id === selectedChoreographyDrill.id)[0]
                }
            )}
            onDragMove={(e) => {
                setSelectedChoreographyTactGroup(prevState => ({
                    ...prevState,
                    choreography_group_object: {
                        ...prevState.choreography_group_object,
                        choreographyDrills: prevState.choreography_group_object.choreographyDrills.map((item) =>
                            item.id === selectedChoreographyDrill.id ? {
                                ...item,
                                x1: firstIndexHandle || bothIndexHandle ? e.target.attrs.x + halfSize : item.x1,
                                y1: firstIndexHandle || bothIndexHandle ? e.target.attrs.y + halfSize : item.y1,
                                x2: !firstIndexHandle || bothIndexHandle ? e.target.attrs.x + halfSize : item.x2,
                                y2: !firstIndexHandle || bothIndexHandle ? e.target.attrs.y + halfSize : item.y2,
                            } : item
                        )
                    }
                }))
            }}

        />);
}

export default ChoreographiesDrillHandle;