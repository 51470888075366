import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {Language as LanguageIcon} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const LanguageSwitcher = ({color = "white"}) => {
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        handleCloseMenu();
    };

    return (
        <>
            <IconButton
                aria-label="language" aria-controls="language-menu" aria-haspopup="true"
                sx={{color: color}} onClick={handleOpenMenu}
            >
                <LanguageIcon/>
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left', // Adjust this as needed
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left', // Adjust this as needed
                }}
            >
                <MenuItem onClick={() => changeLanguage('de')}>
                    {t('translation.german')}
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('en')}>
                    {t('translation.english')}
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('fr')}>
                    {t('translation.french')}
                </MenuItem>
            </Menu>
        </>
    );
}

export default LanguageSwitcher;
