import React from "react";
import {TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


const InstallationDrawingRightContainer = ({installationDrawing, setSelectedInstallationDrawing, sendToServer}) => {

    const {t} = useTranslation();

    if (installationDrawing) {
        return (<>
            <div className={"performerDetailItem"}>
                <Typography variant={"h6"} sx={{marginBottom: "1.2em"}}>{t('detail.installationDrawing.generalInformation')}</Typography>
                <TextField fullWidth value={installationDrawing.name} size={"small"} label={t('detail.choreography.description')}
                           sx={{marginBottom: ".8em"}} variant="outlined"
                           onBlur={(e) => sendToServer(installationDrawing)}
                           onChange={(e) => setSelectedInstallationDrawing({...installationDrawing, name: e.target.value})}
                />
            </div>

        </>)
    }

}


export default InstallationDrawingRightContainer
