import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useTranslation} from "react-i18next";
import DefaultConfirmDialog from "../../components/DefaultConfirmDialog";
import Tooltip from '@mui/material/Tooltip';

const DeleteProgramDialog = ({mainAction, title, description}) => {

    const {t} = useTranslation();
    const dialogRef = React.useRef();

    const handleClickOpen = (e) => {
        if (dialogRef.current) dialogRef.current.openDialog(e);
    };

    return (
        <>
            <Tooltip title={t('dashboard.deleteProgram')}>
                <IconButton aria-label="Clone Program" size="small" onClick={e => handleClickOpen(e)}>
                    <DeleteOutlineIcon fontSize="inherit"/>
                </IconButton>
            </Tooltip>
            <DefaultConfirmDialog
                ref={dialogRef}
                title={title}
                description={description}
                mainActionText={t('delete')}
                mainAction={mainAction}
            />
        </>
    );
}

export default DeleteProgramDialog