import {useTranslation} from "react-i18next";

const Loader = () => {
    const {t, i18n} = useTranslation();


    return (
        <div className="App">
            <img src="xxxx" alt="logo"/>
            <div>loading...</div>
        </div>
    )
}

export default Loader;
