import {Alert, Box, Snackbar} from "@mui/material";
import * as React from "react";
import {forwardRef, useImperativeHandle} from "react";


const SuccessSnackbar = forwardRef(({vertical = "bottom", horizontal = "center"}, ref) => {

    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState("");


    useImperativeHandle(ref, () => ({
        openSnackbar: (text_) => {
            setText(text_);
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Snackbar sx={{width: 500}}
                      anchorOrigin={{vertical, horizontal}} open={open}
                      onClose={handleClose}
                      key={vertical + horizontal}>
                <Alert severity="success" variant="filled">{text}</Alert>
            </Snackbar>
        </Box>
    );
});

export default SuccessSnackbar;
