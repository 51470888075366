import React from 'react';
import {Stage, Layer, Arrow, Group} from 'react-konva';
import InstallationDrawingImage from "../installationDrawings/InstallationDrawingImage";
import ChoreographyAvatar from "./ChoreographyAvatar";
import ChoreographiesDrillHandle from "./ChoreographiesDrillHandle";
import {doTimeRangesIntersect, removeKeysFromDict} from "../../../utils/DefaultHelpers";
import {getTimeRangeOfChoreographyTactGroup} from "../../../utils/ChoreographyHelpers";


const ChoreographiesMiddleEditContainer = ({
                                               installationDrawings,
                                               selectedChoreographyDrill, setSelectedChoreographyDrill,
                                               updateChoreographyDrill, createChoreographyDrill, choreographyTactGroups,
                                               selectedChoreographyTactGroup, setSelectedChoreographyTactGroup,
                                               addDynamicDrillActive, addStaticDrillActive, showAvailablePerformers

                                           }) => {

        const [addingDrill, setAddingDrill] = React.useState(null);

        const onMouseClick = (e) => {

            if (e.target === e.target.getStage()) {
                setSelectedChoreographyDrill(null);
            }

            if (addDynamicDrillActive && selectedChoreographyTactGroup) {
                const newChoreographyDrill = {
                    "x1": Math.floor(e.evt.layerX), "y1": Math.floor(e.evt.layerY),
                    "x2": Math.floor(e.evt.layerX), "y2": Math.floor(e.evt.layerY),
                    "delay": 0, "type": "DYNAMIC", "choreography_group": selectedChoreographyTactGroup.choreography_group_object.id
                }
                setAddingDrill(newChoreographyDrill);
            }

            if (addStaticDrillActive && selectedChoreographyTactGroup) {
                const newChoreographyDrill = {
                    "x1": Math.floor(e.evt.layerX), "y1": Math.floor(e.evt.layerY),
                    "x2": Math.floor(e.evt.layerX), "y2": Math.floor(e.evt.layerY),
                    "delay": 0, "type": "STATIC", "choreography_group": selectedChoreographyTactGroup.choreography_group_object.id
                }
                createChoreographyDrill(newChoreographyDrill);
            }
        };

        const onMouseUp = (e) => {
            if (addingDrill) {
                createChoreographyDrill(addingDrill);
                setAddingDrill(null);
            }
        }

        const onMouseMove = (e) => {
            if (addingDrill) {
                setAddingDrill({...addingDrill, x2: e.evt.layerX, y2: e.evt.layerY})
            }
        }

        const getAvailablePerformers = () => {

            let results = {};
            const sameTimePerformerIds = new Set();

            if (selectedChoreographyTactGroup !== null && selectedChoreographyTactGroup.choreographyDetail.length !== 0) {

                const usedPerformerIds = selectedChoreographyTactGroup.choreography_group_object.choreographyDrills.map(x => x.performer);
                let minTactGroup = choreographyTactGroups.filter(x => x.choreography_group === selectedChoreographyTactGroup.choreography_group).sort((a, b) => a.tact - b.tact)[0];
                const rangeOfSelection = getTimeRangeOfChoreographyTactGroup(minTactGroup);

                for (let choreographyTactGroup of choreographyTactGroups) {

                    if (choreographyTactGroup.choreographyDetail.length === 0) continue;

                    const rangeOfTactGroup = getTimeRangeOfChoreographyTactGroup(choreographyTactGroup);

                    for (let choreographyDrill of choreographyTactGroup.choreography_group_object.choreographyDrills) {

                        if (choreographyDrill.performer_object === null || usedPerformerIds.includes(choreographyDrill.performer)) continue;

                        if (doTimeRangesIntersect(rangeOfTactGroup, rangeOfSelection)) {
                            sameTimePerformerIds.add(choreographyDrill.performer);
                        }

                        if (rangeOfTactGroup.max > rangeOfSelection.min) continue;

                        if (!results[choreographyDrill.performer] || rangeOfTactGroup.max > results[choreographyDrill.performer].timestamp) {
                            results[choreographyDrill.performer] = {
                                timestamp: rangeOfTactGroup.max, x2: choreographyDrill.x2, y2: choreographyDrill.y2,
                                img: choreographyDrill.performer_object.image, name: choreographyDrill.performer_object.name
                            };
                        }

                    }
                }
            }

            removeKeysFromDict(results, Array.from(sameTimePerformerIds))
            return results;
        }

        const lastLocationOfPerformers = getAvailablePerformers();


        return (
            <Stage
                width={window.innerWidth}
                height={window.innerHeight * 0.907}
                onMouseDown={onMouseClick}
                onTouchStart={onMouseClick}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                style={{backgroundColor: "lightgray"}}
            >

                <Layer>

                    {installationDrawings.map((installationDrawing, i) => {
                        return (
                            <InstallationDrawingImage listening={false} key={`install-${i}`} shapeProps={installationDrawing}/>
                        );
                    })}

                    {
                        selectedChoreographyTactGroup && showAvailablePerformers ?
                            Object.entries(lastLocationOfPerformers).map(([key, values], index) => (
                                <ChoreographyAvatar key={`lastloc-${key}-${values.name}`} img={values.img} x={values.x2} y={values.y2} opacity={0.45}
                                                    text={values.name}/>
                            ))
                            : <></>
                    }

                    {selectedChoreographyTactGroup ? selectedChoreographyTactGroup.choreography_group_object.choreographyDrills.map((item, i) => {
                        return (
                            <Group key={`avatar-arrow-idx-${item.id}`}>
                                <Arrow
                                    key={i} onClick={() => setSelectedChoreographyDrill(item)} draggable={false}
                                    fill={"black"} stroke={"black"} strokeWidth={6} points={[item.x1, item.y1, item.x2, item.y2]}/>
                                <ChoreographyAvatar onClick={() => setSelectedChoreographyDrill(item)}
                                                    text={item.performer_object ? item.performer_object.name : ""}
                                                    img={item.performer_object ? item.performer_object.image : null} x={item.x1} y={item.y1}
                                />
                            </Group>
                        );
                    }) : null}


                    {addingDrill !== null ?
                        <Arrow fill={"black"} stroke={"black"} strokeWidth={6} points={[addingDrill.x1, addingDrill.y1, addingDrill.x2, addingDrill.y2]}/>
                        : null}


                    {selectedChoreographyDrill !== null ?
                        <>
                            <ChoreographiesDrillHandle
                                selectedChoreographyTactGroup={selectedChoreographyTactGroup}
                                setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                                selectedChoreographyDrill={selectedChoreographyDrill}
                                updateChoreographyDrill={updateChoreographyDrill}
                                firstIndexHandle={true}
                                bothIndexHandle={selectedChoreographyDrill.type === "STATIC"}/>
                            {
                                selectedChoreographyDrill.type !== "STATIC" ?
                                    <ChoreographiesDrillHandle
                                        selectedChoreographyTactGroup={selectedChoreographyTactGroup}
                                        setSelectedChoreographyTactGroup={setSelectedChoreographyTactGroup}
                                        selectedChoreographyDrill={selectedChoreographyDrill}
                                        updateChoreographyDrill={updateChoreographyDrill}
                                        firstIndexHandle={false}
                                        bothIndexHandle={false}/>
                                    : null
                            }
                        </> : null
                    }

                </Layer>
            </Stage>
        );
    }
;

export default ChoreographiesMiddleEditContainer;


