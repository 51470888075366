import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";
import styles from './Dashboard.css'
import {programService} from "../../services/ProgramService";
import Card from '@mui/material/Card';
import {Typography, Box, IconButton} from "@mui/material";
import AddProgramDialog from "./AddProgramDialog";
import DefaultHeader from "../../components/DefaultHeader";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RenameProgramDialog from "./RenameProgramDialog";
import SuccessSnackbar from "../../components/SuccessSnackbar";
import DeleteProgramDialog from "./DeleteProgramDialog";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import DashboardProgramCard from "./DashboardProgramCard";


/**
 * This Component provides the Overview for all Children.
 *
 * @return {JSX.Element}
 */
const Dashboard = () => {

    const {t} = useTranslation()

    const [programs, setPrograms] = useState([]);
    const successSnackbarRef = useRef();
    const errorSnackbarRef = useRef();

    const cloneProgram = (e) => {
        e.stopPropagation();
        let programId_ = e.currentTarget.getAttribute('programId');

        if (programId_)
            programService.cloneProgram(programId_).then((response) => {
                if (response.status === 200) {
                    setPrograms([...programs, response.data]);
                    if (successSnackbarRef.current) successSnackbarRef.current.openSnackbar(t('dashboard.programCloneSuccess'));
                } else {

                }
            })
    }

    const deleteProgram = (programId) => {
        programService.deleteProgram(programId).then((response) => {
            if (response.status === 204) {
                setPrograms([...programs].filter(x => x.id !== programId));
                if (successSnackbarRef.current) successSnackbarRef.current.openSnackbar(t('dashboard.programDeleteSuccess'));
            } else {

            }
        })
    }


    const fetchData = async () => {
        programService.getAllPrograms().then(allPrograms => setPrograms(allPrograms["data"]))
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <DefaultHeader></DefaultHeader>
            <main>
                <div style={{padding: "2em"}}>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center"}}>
                        <h1>{t('dashboard.allPrograms')}</h1>
                        <AddProgramDialog/>
                    </div>
                    <div style={{display: "flex"}}>
                        {programs.map((program, i) => {
                            return (
                                <DashboardProgramCard program={program} cloneProgram={cloneProgram} deleteProgram={deleteProgram}
                                                      fetchData={fetchData} successSnackbarRef={successSnackbarRef}/>
                            );
                        })}
                        {programs.length === 0 ?
                            <div>{t('dashboard.noProgrammMessage')}</div> : <></>}
                    </div>
                </div>
                <SuccessSnackbar ref={successSnackbarRef}></SuccessSnackbar>
                <ErrorSnackbar ref={errorSnackbarRef}></ErrorSnackbar>
            </main>
        </>)
}

export default Dashboard
