import InstallationDrawingMain from "./views/program_detail/installationDrawings/InstallationDrawingMain";
import {Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Loader from "./Loader";
import Login from "./views/login/Login";
import Dashboard from "./views/dashboard/Dashboard";
import i18n from './i18n';
import {I18nextProvider} from 'react-i18next';
import PerformerMain from "./views/program_detail/performer/PerformerMain";
import ChoreographiesMain from "./views/program_detail/choreographies/ChoreographiesMain";
import MusicsMain from "./views/program_detail/musics/MusicsMain";
import PasswordReset from "./views/password_reset/RequestResetPassword";
import PerformPasswordReset from "./views/password_reset/PerformPasswordReset";
import Register from "./views/register/Register";
import ProfileOverview from "./views/profile/ProfileOverview";
import ProfileChangePassword from "./views/profile/ProfileChangePassword";
import ProfileAbo from "./views/profile/ProfileAbo";
import {createTheme, ThemeProvider} from "@mui/material";

function App() {

    const theme = createTheme({

        components: {
            MuiButton: {
                styleOverrides: {
                    contained: {
                        boxShadow: 'none',
                        color: 'white'
                    },
                    outlined: {
                        boxShadow: 'none',
                    },
                },
            },
        },
        palette: {
            primary: {
                light: '#F0A343',
                main: '#F06543',
            },
            secondary: {
                light: '#404547',
                main: '#313638',
                dark: '#222729',
            },
            text: {
                primary: '#313638',
                secondary: '#424A4C'
            },
            background: {
                default: "#F8F9FB"
            }
        },
    });


    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<Loader/>}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="" element={<Login/>}/>
                            <Route path="program/:programId/equipments" element={<InstallationDrawingMain/>}/>
                            <Route path="program/:programId/performer" element={<PerformerMain/>}/>
                            <Route path="program/:programId/choreographies" element={<ChoreographiesMain/>}/>
                            <Route path="program/:programId/musics" element={<MusicsMain/>}/>
                            <Route path="dashboard" element={<Dashboard/>}/>
                            <Route path="login" element={<Login/>}/>
                            <Route path="profile" element={<ProfileOverview/>}/>
                            <Route path="profile/changePassword" element={<ProfileChangePassword/>}/>
                            <Route path="profile/abo" element={<ProfileAbo/>}/>
                            <Route path="profile" element={<ProfileOverview/>}/>
                            <Route path="register" element={<Register/>}/>
                            <Route path="requestPasswordReset" element={<PasswordReset/>}/>
                            <Route path="performPasswordReset" element={<PerformPasswordReset/>}/>
                        </Routes>
                    </BrowserRouter>
                </I18nextProvider>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;

