import ProgramHeader from "../../components/ProgramHeader";
import React, {useEffect, useRef} from "react";
import DefaultContentContainer from "../../components/DefaultContentContainer";
import DefaultHeader from "../../components/DefaultHeader";
import {Alert, Button, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography} from "@mui/material";
import ContentCopy from '@mui/icons-material/ContentCopy';
import {userService} from "../../services/UserService";
import ProfileMenu from "./ProfileMenu";
import {authenticationService} from "../../services/AuthenticationService";
import {useTranslation} from "react-i18next";


const ProfileChangePassword = ({}) => {

    const {t} = useTranslation();

    const [user, setUser] = React.useState(null);

    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [failOpen, setFailOpen] = React.useState(false);


    useEffect(() => {
        userService.getUser().then(userResponse => setUser(userResponse["data"]));
    }, []);


    const handleKeyDown = (e) => {

    }

    const changePassword = (e) => {
        authenticationService.changePassword(oldPassword, newPassword, newPassword)
            .then(response => {
                if (response.status === 200) {
                    setSuccessOpen(true);
                    setFailOpen(false);
                } else {
                    setSuccessOpen(false);
                    setFailOpen(true);
                }
            });
    }

    return (
        <DefaultContentContainer onKeyDown={(e) => handleKeyDown(e)}>

            <DefaultHeader>
                <ProgramHeader programId={-1} selectedMenu={"choreographies"}/>
            </DefaultHeader>

            <main>
                <div style={{padding: "4%"}}>

                    <div style={{paddingLeft: "3em", paddingBottom: "2em"}}>
                        <Typography variant="h2">
                            {t('profile.title')}
                        </Typography>
                    </div>

                    <div style={{display: "flex"}}>
                        <div style={{flexBasis: "25%"}}>
                            <div style={{borderRight: "1px solid lightGray", paddingLeft: "3em"}}>
                                <ProfileMenu/>
                            </div>
                        </div>

                        <div style={{flexBasis: "75%"}}>
                            <div style={{padding: "3em"}}>

                                <Typography variant="h4" style={{paddingBottom: "1em"}}>
                                    {t('profile.changePassword')}
                                </Typography>

                                {user ? (
                                    <>
                                        <TextField size={"small"} label={t('profile.oldPassword')} type="password"
                                                   InputLabelProps={{style: {fontSize: '0.9m'}}} autoComplete="off"
                                                   onChange={e => setOldPassword(e.target.value)}
                                                   sx={{width: "35%", marginBottom: ".8em", marginRight: "1%"}}

                                        />

                                        <TextField size={"small"} label={t('profile.newPassword')} type="password"
                                                   InputLabelProps={{style: {fontSize: '0.9m'}}} autoComplete="new-password"
                                                   onChange={e => setNewPassword(e.target.value)}
                                                   sx={{width: "35%", marginBottom: ".8em", marginRight: "1%"}}

                                        />
                                        <br/>
                                        <br/>
                                        <Collapse in={failOpen} sx={{width: "71%"}}>
                                            <Alert severity="error">{t('profile.passwordChangeFail')}</Alert>
                                        </Collapse>

                                        <Collapse in={successOpen} sx={{width: "71%"}}>
                                            <Alert severity="success">{t('profile.passwordChangeSuccess')}</Alert>
                                        </Collapse>
                                        <br/>
                                        <Button variant={"contained"} onClick={e => changePassword(e)}>{t('profile.save')}</Button>
                                    </>) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </DefaultContentContainer>

    )
}

export default ProfileChangePassword