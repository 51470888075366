import ProgramHeader from "../../components/ProgramHeader";
import React, {useEffect} from "react";
import DefaultContentContainer from "../../components/DefaultContentContainer";
import DefaultHeader from "../../components/DefaultHeader";
import {Typography} from "@mui/material";
import {userService} from "../../services/UserService";
import ProfileMenu from "./ProfileMenu";
import {useTranslation} from "react-i18next";

const ProfileAbo = ({}) => {

    const {t} = useTranslation();

    const [user, setUser] = React.useState(null);

    useEffect(() => {
        userService.getUser().then(userResponse => setUser(userResponse["data"]));
    }, []);


    return (
        <DefaultContentContainer onKeyDown={(e) => null}>

            <DefaultHeader>
                <ProgramHeader programId={-1} selectedMenu={"choreographies"}/>
            </DefaultHeader>

            <main>
                <div style={{padding: "4%"}}>

                    <div style={{paddingLeft: "3em", paddingBottom: "2em"}}>
                        <Typography variant="h2">
                            {t('profile.title')}
                        </Typography>
                    </div>

                    <div style={{display: "flex"}}>
                        <div style={{flexBasis: "25%"}}>
                            <div style={{borderRight: "1px solid lightGray", paddingLeft: "3em"}}>
                                <ProfileMenu/>
                            </div>
                        </div>

                        <div style={{flexBasis: "75%"}}>
                            <div style={{padding: "3em"}}>

                                <Typography variant="h4" style={{paddingBottom: "1em"}}>
                                    {t('profile.subscription')}
                                </Typography>

                                {user ?
                                    <>
                                        <Typography>
                                            {t('profile.aboValidUntil')} {user.license_validation_date}
                                        </Typography>

                                    </> : null
                                }

                            </div>
                        </div>

                    </div>
                </div>

            </main>
        </DefaultContentContainer>

    )
}

export default ProfileAbo