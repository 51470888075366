import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import _ from './ResizableComponent.css'
import {userService} from "../services/UserService";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";


const OverlayContainer = styled("div")(({theme}) => ({
    position: "absolute",
    left: 0,
    width: "100%",
    height: "91%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9999,
    top: "9%",
    pointerEvents: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "36px",
}));


const ResizeableComponent = forwardRef(({children}, ref) => {

    const {t} = useTranslation();

    const [panelSize, setPanelSizes] = useState([window.innerWidth * 0.18, window.innerWidth * 0.57, window.innerWidth * 0.25]);
    const [resizeDeltas, setResizeDeltas] = useState([0, 0, 0]);
    const [resizeAspect, setResizeAspect] = useState([0.18, 0.57, 0.25]);

    const [initialPosition, setInitialPosition] = useState(0);
    const [currentPanel, setCurrentPanel] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    useImperativeHandle(ref, () => ({
        getWidth: (idx) => {
            return panelSize[idx] + resizeDeltas[idx];
        }
    }));

    useEffect(() => {
        const handleResize = () => {
            setPanelSizes([window.innerWidth * resizeAspect[0], window.innerWidth * resizeAspect[1], window.innerWidth * resizeAspect[2]]);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [resizeAspect]);


    const [user, setUser] = React.useState(null);

    useEffect(() => {
        userService.getUser().then(userResponse => setUser(userResponse["data"]));
    }, []);


    const resizePanel = (event) => {
        if (isDragging) {
            const delta = event.clientX - initialPosition;
            let tmpResizeDelta = [0, 0, 0];
            tmpResizeDelta[currentPanel] += delta;
            tmpResizeDelta[currentPanel + 1] -= delta;
            setResizeDeltas(tmpResizeDelta);
        }
    }

    const startResize = (event, idx) => {
        if (!isDragging) {
            setIsDragging(true);
            setCurrentPanel(idx);
            setInitialPosition(event.clientX);
        }
    }

    const stopResize = (event) => {
        if (isDragging) {
            setIsDragging(false);
            setInitialPosition(0);
            let sumArray = panelSize.map(function (num, idx) {
                return num + resizeDeltas[idx];
            });

            const sum = sumArray.reduce((acc, num) => acc + num, 0);
            const percentages = sumArray.map(num => num / sum);

            setPanelSizes(sumArray);
            setResizeAspect(percentages);
            setResizeDeltas([0, 0, 0]);
        }
    }

    const validLicence = user &&  new Date(user.license_validation_date) < new Date();


    return (
        <>

            {validLicence
                ? <OverlayContainer>
                    {t('licenceExpired')}
                </OverlayContainer>
                : null
            }


            <div className="panelContainer" onMouseMove={resizePanel} onMouseLeave={stopResize} onMouseUp={stopResize}>
                <div className="panel" style={{width: `${panelSize[0] + resizeDeltas[0]}px`}}>
                    {children[0]}
                </div>
                <div className="resizer" onMouseDown={e => startResize(e, 0)}/>
                <div className="panel" style={{width: `${panelSize[1] + resizeDeltas[1]}px`, border: 0}}>
                    {React.cloneElement(children[1], {width: panelSize[1] + resizeDeltas[1]})}
                </div>
                <div className="resizer" onMouseDown={e => startResize(e, 1)}/>
                <div className="panel" style={{width: `${panelSize[2] + resizeDeltas[2]}px`}}>
                    {children[2]}
                </div>
            </div>
        </>

    );
});

export default ResizeableComponent

