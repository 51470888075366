import DefaultListItem from "../../../components/DefaultListItem";
import React from "react";


const InstallationDrawingLeftContainer = ({installationDrawings, selectedInstallationDrawing, setSelectedInstallationDrawing}) => {


    return (
        <div>
            <div style={{marginTop: "10px"}}>
                {installationDrawings.map((installationDrawing, i) => {
                    return (
                        <DefaultListItem text={installationDrawing.name} key={i}
                                         selected={selectedInstallationDrawing && installationDrawing.id === selectedInstallationDrawing.id}
                                         onClick={e => setSelectedInstallationDrawing(installationDrawing)}>

                        </DefaultListItem>
                    )
                })}
            </div>
        </div>
    )
}


export default InstallationDrawingLeftContainer;