import axios from "axios";
import {localStorageService} from "../services/LocalStorageService";
import {authenticationService} from "../services/AuthenticationService";


// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token && token !== "undefined") {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });


//Add a response interceptor

axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === 'http://127.0.0.1:8000/token/') {
        window.location.href = "/login";
        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        try {
            await authenticationService.refreshToken();
            originalRequest.headers["Authorization"] = `Bearer ${localStorageService.getAccessToken()}`;
            return axios(originalRequest);
        } catch (_) {
            window.location.href = "/login";
        }
    }
    return error.response;
});