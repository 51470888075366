import React from 'react';
import {Image, Transformer} from 'react-konva';
import useImage from "use-image";

const InstallationDrawingImage = ({listening = true, shapeProps, isSelected, onSelect, onChange}) => {

    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const [image] = useImage(process.env.REACT_APP_INSTALLATION_DRAWINGS_IMG_PATH + shapeProps.image);

    return (
        <React.Fragment>
            <Image
                listening={listening}
                image={image}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                x={shapeProps.x}
                y={shapeProps.y}
                height={shapeProps.height}
                width={shapeProps.width}
                rotation={shapeProps.rotation}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                        rotation: Math.round(e.target.attrs.rotation)
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: Math.round(node.x()),
                        y: Math.round(node.y()),
                        width: Math.max(5, Math.round(node.width() * scaleX)),
                        height: Math.max(Math.round(node.height() * scaleY)),
                        rotation: Math.round(e.target.attrs.rotation)
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default InstallationDrawingImage;