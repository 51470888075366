import React from 'react';
import { styled } from '@mui/material/styles';
import {StyledMenuItem} from "../../../components/StyledMenuItem";
import {useTranslation} from "react-i18next";


const Divider = styled('div')({
    height: '100%',
    width: '1px',
    background: '#333',
});

const ChoreographiesActionBar = ({
                       selectedChoreographyTactGroup,
                       addDynamicDrillActive,
                       addStaticDrillActive,
                       showAvailablePerformers,
                       setAddDynamicDrillActive,
                       setAddStaticDrillActive,
                       setShowAvailablePerformers,
                   }) => {

    const {t} = useTranslation();

    return (
        <div className="horiz-menu" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            {selectedChoreographyTactGroup ? (
                <>
                    <StyledMenuItem
                        onClick={(e) => {
                            setAddDynamicDrillActive(!addDynamicDrillActive);
                            setAddStaticDrillActive(false);
                        }}
                        isactive={addDynamicDrillActive}
                    >
                        {t('detail.choreography.addArrow')}
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={(e) => {
                            setAddStaticDrillActive(!addStaticDrillActive);
                            setAddDynamicDrillActive(false);
                        }}
                        isactive={addStaticDrillActive}
                    >
                        {t('detail.choreography.addPoint')}
                    </StyledMenuItem>
                    <Divider />
                </>
            ) : null}
            <StyledMenuItem
                onClick={(e) => setShowAvailablePerformers(!showAvailablePerformers)}
                isactive={showAvailablePerformers}
            >
                {t('detail.choreography.showAvailablePerformer')}
            </StyledMenuItem>
        </div>
    );
};

export default ChoreographiesActionBar;
