import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Slider, Typography} from "@mui/material";
import {forwardRef, useState} from "react";
import {useTranslation} from "react-i18next";
import CallSplitIcon from "@mui/icons-material/CallSplit";

const MusicSplitMusicDialog = forwardRef(({music, splitMusic}, ref) => {

    const [open, setOpen] = React.useState(false);
    const [splitTact, setSplitTact] = useState(1);

    const {t} = useTranslation();


    const splitMusic_ = () => {
        const splitTact_ = splitTact - 1;
        const musicToUpdate = {...music, end_time: music.start_time + (music.tact * splitTact_ * music.tact_duration) - 1};
        const musicToCreate = {...music, start_time: music.start_time + (music.tact * splitTact_ * music.tact_duration)};
        splitMusic(musicToUpdate, musicToCreate);
        handleClose();
    };

    const openSplitSongDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSliderChange = (event, newValue) => {
        setSplitTact(newValue);
    };

    const numberOfIterations = 1 + Math.floor((music.end_time - music.start_time) / (music.tact_duration * music.tact));

    return (
        <>
            <Button startIcon={<CallSplitIcon/>} sx={{width: "50%"}}
                    onClick={(e) => openSplitSongDialog()}>
                {t('detail.music.splitSong')}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('detail.music.splitSong')}</DialogTitle>
                <DialogContent>

                    {numberOfIterations > 1
                        ? <>
                            <Slider value={splitTact} step={1} marks min={2} max={numberOfIterations} valueLabelDisplay="on"
                                    onChange={handleSliderChange} sx={{width: "500px", marginTop: "34px"}}/>
                            <Typography>
                                {t('detail.music.enterTactForSplitMessage')}
                            </Typography>
                        </>
                        : <Typography>{t('detail.music.tooShortForSplitMessage')}</Typography>}
                </DialogContent>

                <DialogActions sx={{margin: ".5em", marginBottom: "1em"}}>
                    <Button onClick={handleClose}>{t('dashboard.cancel')}</Button>
                    <Button variant={"contained"} onClick={e => splitMusic_()} disabled={numberOfIterations <= 1}>
                        {t('dashboard.save')}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
})

export default MusicSplitMusicDialog;
