export const isNumber = (value) => {
    return /^\d*\.?\d*$/.test(value);
}

export const generateNumberList = (number) => {
    if (number < 1) {
        throw new Error('Input number should be a positive integer.');
    }
    const list = [];
    for (let i = 1; i <= number; i++) {
        list.push(i);
    }
    return list;
}

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

export const numberWithinRange = (number, min, max) => {
    return (number >= min && number <= max);
}

export const doTimeRangesIntersect = (timeRange1, timeRange2) => {
    return (numberWithinRange(timeRange1.min, timeRange2.min, timeRange2.max) || numberWithinRange(timeRange1.max, timeRange2.min, timeRange2.max) ||
        (numberWithinRange(timeRange2.min, timeRange1.min, timeRange1.max) && numberWithinRange(timeRange2.max, timeRange1.min, timeRange1.max)))
}

export const removeKeysFromDict = (dict, keys) => {
    keys.forEach(id => delete dict[id]);
}


export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};