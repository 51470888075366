import axios from 'axios';
import {requestService} from "./RequestService";

export const musicService = {
    getAllMusics, updateMusic, createMusic, deleteMusic, autoAnalyseMusic, autoAnalyseMusicWithStartStop, getWaveFormat
}

async function getAllMusics() {
    const url = process.env.REACT_APP_MUSIC_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function updateMusic(music) {
    const url = process.env.REACT_APP_MUSIC_URL + music.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, music, config);
}

async function createMusic(music) {
    const url = process.env.REACT_APP_MUSIC_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, music, config);
}

async function deleteMusic(musicId) {
    const url = process.env.REACT_APP_MUSIC_URL + musicId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}

async function autoAnalyseMusic(programId) {
    const url = process.env.REACT_APP_MUSIC_URL + programId + "/analyse/"
    let config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function autoAnalyseMusicWithStartStop(programId, data) {
    const url = process.env.REACT_APP_MUSIC_URL + "analyseWithTimeStamps/"
    let config = requestService.authRequestConfig();
    return await axios.post(url, data, config);
}

async function getWaveFormat(programId) {
    const url = process.env.REACT_APP_MUSIC_URL + programId + "/waveformat/"
    let config = requestService.authRequestConfig();
    return await axios.get(url, config);
}