import { styled } from '@mui/material/styles';

// Define the styled component
const CustomScrollbar = styled('div')`
   width: 1000px;
   height: 320px;
   overflow-x: auto;
   overflow-y: hidden;
   scrollbar-width: thin;

   &::-webkit-scrollbar {
      width: 10px;
   }

   &::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background: #555;
   }
`;


export default CustomScrollbar;