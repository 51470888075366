import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import PasswordIcon from '@mui/icons-material/Password';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import {authenticationService} from "../../services/AuthenticationService";
import {useTranslation} from "react-i18next";

const ProfileMenu = ({}) => {

    const {t} = useTranslation();

    let navigate = useNavigate();

    const logout = () => {
        authenticationService.logout();
        navigate("/");
    }


    return (
        <List>
            <ListItem disablePadding>

                <ListItemButton onClick={e => navigate("/profile")}>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('profile.overview')}/>
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={e => navigate("/profile/changePassword")}>
                    <ListItemIcon>
                        <PasswordIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('profile.changePassword')}/>
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={e => navigate("/profile/abo")}>
                    <ListItemIcon>
                        <SubscriptionsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('profile.subscription')}/>
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={e => logout()}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('profile.logout')}/>
                </ListItemButton>
            </ListItem>
        </List>

    );
}


export default ProfileMenu;