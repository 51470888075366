import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {forwardRef, useImperativeHandle} from "react";
import {useTranslation} from "react-i18next";


const DefaultConfirmDialog = forwardRef(({title, description, mainActionText, mainAction}, ref) => {

    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        openDialog: (e) => {
            e.stopPropagation();
            setOpen(true);        }
    }));


    return (
        <Dialog onClick={e => e.stopPropagation()}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{margin: "1em"}}>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button onClick={mainAction} variant={"contained"} autoFocus>{mainActionText}</Button>
            </DialogActions>
        </Dialog>
    )
});


export default DefaultConfirmDialog