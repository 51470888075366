import axios from 'axios';
import {requestService} from "./RequestService";

export const choreographyService = {
    getAllChoreographyGroups, updateChoreographyGroup, createChoreographyGroup, deleteChoreographyGroup,
    updateChoreographyDetail, createChoreographyDetail, deleteChoreographyDetail,
    updateChoreographyDrill, createChoreographyDrill, deleteChoreographyDrill,
    getAllChoreographyTactGroups, updateChoreographyTactGroup, createChoreographyTactGroup, deleteChoreographyTactGroup
}


////////////////////////// GROUP ///////////////////////////////////
async function getAllChoreographyGroups() {
    const url = process.env.REACT_APP_CHOREOGRAPHY_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function updateChoreographyGroup(choreographyGroup) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_URL + choreographyGroup.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, choreographyGroup, config);
}

async function createChoreographyGroup(choreographyGroup) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, choreographyGroup, config);
}

async function deleteChoreographyGroup(choreographyGroupId) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_URL + choreographyGroupId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}


////////////////////////// DETAIL ///////////////////////////////////
async function updateChoreographyDetail(choreographyDetail) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DETAIL_URL + choreographyDetail.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, choreographyDetail, config);
}

async function createChoreographyDetail(choreographyDetail) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DETAIL_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, choreographyDetail, config);
}

async function deleteChoreographyDetail(choreographyDetailId) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DETAIL_URL + choreographyDetailId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}


////////////////////////// DRILL ///////////////////////////////////
async function updateChoreographyDrill(choreographyDrill) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DRILL_URL + choreographyDrill.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, choreographyDrill, config);
}

async function createChoreographyDrill(choreographyDrill) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DRILL_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, choreographyDrill, config);
}

async function deleteChoreographyDrill(choreographyDrillId) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_DRILL_URL + choreographyDrillId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}



////////////////////////// TACT GROUP ///////////////////////////////////
async function getAllChoreographyTactGroups() {
    const url = process.env.REACT_APP_CHOREOGRAPHY_TACT_GROUP_URL
    const config = requestService.authRequestConfig();
    return await axios.get(url, config);
}

async function updateChoreographyTactGroup(choreographyGroup) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_TACT_GROUP_URL + choreographyGroup.id + "/"
    let config = requestService.authRequestConfig();
    return await axios.put(url, choreographyGroup, config);
}

async function createChoreographyTactGroup(choreographyGroup) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_TACT_GROUP_URL
    let config = requestService.authRequestConfig();
    return await axios.post(url, choreographyGroup, config);
}

async function deleteChoreographyTactGroup(choreographyGroupId) {
    const url = process.env.REACT_APP_CHOREOGRAPHY_TACT_GROUP_URL + choreographyGroupId + "/"
    let config = requestService.authRequestConfig();
    return await axios.delete(url, config);
}