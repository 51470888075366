import {styled} from "@mui/material/styles";
import {Checkbox} from "@mui/material";


export const RunStopCheckbox = styled(Checkbox)(({theme}) => ({
    paddingRight: "1%",
    paddingLeft: "1%",
    height: "40px",
    marginRight: "1%",
    color: theme.palette.primary.main,
    border: `1px ${theme.palette.primary.main} solid`,
    borderRadius: "4px"
}));